import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  lazy,
  Suspense,
} from 'react'
import { Variables } from '../Variables'
import { format, max } from 'date-fns'
import 'bootstrap/dist/css/bootstrap.css'
import { Tabs, Tab } from 'react-bootstrap'
import { TabPane } from 'react-bootstrap'

import { CasehistorysubTab } from './CasehistorysubTab'
import { SpeechIntSubTab } from './SpeechIntSubTab'
import { VisitProvider } from '../Provider/Visitprovider'
import axios from 'axios'
import { useUser } from '../Provider/UserContext'
//import { POCare } from '../POC/POCare'
import { SoapNoteUI } from '../ProgressNote/SoapNote/SoapNoteUI'
import { ProgressNoteUI } from '../ProgressNote/ProgressNotes/ProgressNoteUI'
import { DischargeSummaryUI } from '../ProgressNote/DischargeSummary/DischargeSummaryUI'
import formatDate from '../Utility/Utility'
import { IntNoms } from '../DischargeNoms/IntNoms/IntNoms'
import { LanguageTabUI } from '../FCM/LanguageTabUI'
import { LanguageExpression } from '../FCM/LanguageExpression/LanguageExpression'
import { CognitionTabUI } from '../FCM/Cognition/CognitionTabUI'
import { SwallowingTabUI } from '../FCM/Swallowing/SwallowingTabUI'
import { FeedingTabUI } from '../FCM/Feeding/FeedingTabUI'
import { VoiceTabUI } from '../FCM/Voice/VoiceTabUI'
import { FluencyTabUI } from '../FCM/Fluency/FluencyTabUI'
import { PragmaticTabUI } from '../FCM/Pragmatic/PragmaticTabUI'
import { AACTabUI } from '../FCM/AAC/AACTabUI'

const POCare = lazy(() => import('../POC/POCare'))

const apiUrl = Variables.API_URL
const TabContentWithBorder = ({ children }) => (
  <div style={{ border: '0.5px solid #ccc', padding: '10px' }}>{children}</div>
)

export const MainTab = ({ activeTab, handleTabChange, refreshSignLock }) => {
  const { userDetails } = useUser()
  const VisitId = useContext(VisitProvider)
  const [tabVisibility, setTabVisibility] = useState({})
  const [refreshData, setRefreshData] = useState(false)
  const [visitType, setVisitType] = useState(0)
  const [NoOfDays, setNoOfDays] = useState(0)
  const [isProgressNote, setIsProgressNote] = useState(false)
  const [IsClientDischarge, setIsClientDischarge] = useState(false)

  const [headerData, setheaderData] = useState()
  const [FollowUpVisitCount, setFollowUpVisitCount] = useState(0)
  const [userDetailsC, setUserDetailsC] = useState(null)
  const [age, setAge] = useState(0)
  const [isAdult, setIsAdult] = useState(false)
  const [childActiveTab, setChildActiveTab] = useState('')
  const caseHistorySubtabRef = useRef()
  const SpeechIntSubTabRef = useRef()
  const SLESubTabRef = useRef()
  const SLCSubTabRef = useRef()
  const COGSubTabRef = useRef()
  const FEEDSubTabRef = useRef()
  const VOICSubTabRef = useRef()
  const SWALSubTabRef = useRef()
  const FLUSubTabRef = useRef()
  const PRAGSubTabRef = useRef()
  const AACSubTabRef = useRef()
  const POCRef = useRef()
  const SoapNoteUIRef = useRef()
  const ProgressNoteUIRef = useRef()
  const DischargeSummaryUIRef = useRef()
  const IntNomsRef = useRef()
  const [DisplayTabNote, setDisplayNote] = useState(0)

  useEffect(() => {
    if (userDetails) {
      setVisitType(userDetails.VisitTypeId)
      setAge(userDetails.Age)
      if (userDetails.Age >= 18) {
        setIsAdult(true)
      } else {
        setIsAdult(false)
      }
      //alert(isAdult);
    }
  }, [userDetails, VisitId])

  useEffect(() => {
    if (userDetails) {
      //alert(JSON.stringify(userDetails));
      // setUserDetailsC(userDetails);
      const fetchData = async () => {
        if (VisitId != null && userDetails.Clientid !== null) {
          // alert(VisitId);
          const visitdate = formatDate(
            userDetails.Scheduleddateto,
            false,
            false,
          ).toString()
          // alert(JSON.stringify(`ProgressDis/GetHeader/${VisitId}/${userDetails.Clientid}/${visitdate}`));
          try {
            const response = await axios.get(
              apiUrl +
                `ProgressDis/GetHeader/${VisitId}/${userDetails.Clientid}/${visitdate}`,
            )
            const data = response.data.Result
            // alert(JSON.stringify(response.data.Result))

            setIsProgressNote(data.IsProgressNote)
            setNoOfDays(data.NoOfDays)
            setFollowUpVisitCount(data.FollowUpVisitCount)
            setIsClientDischarge(data.IsClientDischarge)
            const formattedData = {
              Treatment: data.Treatment,
              Billing: data.Billing,
              SecondaryDiagnosis: data.SecondaryDiagnosis,
              LastPocDate: data.LastPocDate,
              LastPNDate: data.LastPNDate,
              LastDSDate: data.LastDSDate,
              FollowUpVisitCount: data.FollowUpVisitCount,
              NoOfDays: data.NoOfDays,
              IsProgressNote: data.IsProgressNote,
              IsClientDischarge: data.IsClientDischarge,
            }

            // Convert the formatted data to a JSON string
            // const formattedDataString = JSON.stringify(formattedData);
            setheaderData(formattedData)
            //console.log(formattedDataString);
          } catch (error) {
            console.log('Error fetching main tab ProgressDis/GetHeader:', error)
          }
        }
      }

      fetchData()
    }
  }, [userDetails])

  useEffect(() => {
    if (VisitId) {
      const apiSubUrl =
        apiUrl + (isAdult ? 'CHA/Medical/' : 'CaseHistory/Medical/') + VisitId
      //alert(VisitId);
      axios
        .get(apiSubUrl)
        .then((response) => {
          const data = response.data.Result
          //alert(JSON.stringify(data));
          if (data && data.FCM_Ids !== null) {
            const fcmStringIds = data.FCM_Ids

            const visibilityArray = fcmStringIds.split(',')
            const tabVisibilityData = {
              PRAG: visibilityArray.includes('PRAG'),
              COG: visibilityArray.includes('COG'),
              FEED: visibilityArray.includes('FEED'),
              FLU: visibilityArray.includes('FLU'),
              READ: visibilityArray.includes('READ'),
              PRC: visibilityArray.includes('PRC'),
              VOIC: visibilityArray.includes('VOIC'),
              WRIT: visibilityArray.includes('WRIT'),
              SWAL: visibilityArray.includes('SWAL'),
              SLE: visibilityArray.includes('SLE'),
              SLC: visibilityArray.includes('SLC'),
              INT: visibilityArray.includes('INT'),
              ELIT: visibilityArray.includes('ELIT'),
              AAC: visibilityArray.includes('AAC'),
            }
            setTabVisibility(tabVisibilityData)

            //alert(JSON.stringify(tabVisibilityData));
          }
        })
        .catch((error) =>
          //alert(error)
          console.log('Error fetching tab visibility:', error),
        )
    }
  }, [VisitId, refreshData, isAdult])

  const triggerDisplayNote = (displayTab) => {
    //alert(displayTab)
    // Convert displayTab to a number, return 0 if displayTab is null
    const numericDisplayTab = displayTab === null ? 0 : +displayTab
    setDisplayNote(numericDisplayTab)
  }

  const triggerRefresh = () => {
    setRefreshData((prevState) => !prevState)
  }

  const [activeKey, setActiveKey] = useState('CHC')

  useEffect(() => {
    if (visitType === 2 || visitType === 4) {
      handleTabSelect('SN', false)
    } else {
      handleTabSelect('CHC', false)
    }
  }, [visitType, VisitId])

  const handleTabSelect = (eventKey, isMount = true) => {
    //if (IsSignLock === false) {
    // alert("Event: "+ eventKey);
    // alert('Active Tab: ' + activeTab)
    //alert('childActiveTab : ' + childActiveTab)

    if (activeTab === 'CHC' && childActiveTab === 'general') {
      if (isMount) {
        //alert(childActiveTab);
        caseHistorySubtabRef.current.SaveGeneral()
        setChildActiveTab('')
      }
    } else if (activeTab === 'CHC' && childActiveTab === 'medical') {
      if (isMount) {
        caseHistorySubtabRef.current.SaveMedical()
        //alert("medical saved");
        setChildActiveTab('')
      }
    }

    if (activeTab === 'INT' && childActiveTab === 'AS') {
      if (isMount) {
        SpeechIntSubTabRef.current.SaveAss()
        setChildActiveTab('')
      }
    } else if (activeTab === 'INT' && childActiveTab === 'NM') {
      if (isMount) {
        SpeechIntSubTabRef.current.SaveNOMS()
        //alert("medical saved");
        setChildActiveTab('')
      }
    } else if (activeTab === 'INT' && childActiveTab === 'GL') {
      if (isMount) {
        SpeechIntSubTabRef.current.SaveGOAL()
        //alert("medical saved");
        setChildActiveTab('')
      }
    }

    //SLE handle the auto save here
    if (activeTab === 'SLC' && childActiveTab === 'AS') {
      if (isMount) {
        SLCSubTabRef.current.SaveAss()
        setChildActiveTab('')
      }
    } else if (activeTab === 'SLC' && childActiveTab === 'NM') {
      if (isMount) {
        SLCSubTabRef.current.SaveNOMS()
        //alert("medical saved");
        setChildActiveTab('')
      }
    } else if (activeTab === 'SLC' && childActiveTab === 'GL') {
      if (isMount) {
        SLCSubTabRef.current.SaveGOAL()
        //alert("medical saved");
        setChildActiveTab('')
      }
    }
    //SLE handle the auto save here
    if (activeTab === 'SLE' && childActiveTab === 'AS') {
      if (isMount) {
        SLESubTabRef.current.SaveAss()
        setChildActiveTab('')
      }
    } else if (activeTab === 'SLE' && childActiveTab === 'NM') {
      if (isMount) {
        SLESubTabRef.current.SaveNOMS()
        //alert("medical saved");
        setChildActiveTab('')
      }
    } else if (activeTab === 'SLE' && childActiveTab === 'GL') {
      if (isMount) {
        SLESubTabRef.current.SaveGOAL()
        //alert("medical saved");
        setChildActiveTab('')
      }
    }

    // //COG handle the auto save here
    if (activeTab === 'COG' && childActiveTab === 'AS') {
      if (isMount) {
        COGSubTabRef.current.SaveAss()
        setChildActiveTab('')
      }
    } else if (activeTab === 'COG' && childActiveTab === 'NM') {
      if (isMount) {
        COGSubTabRef.current.SaveNOMS()
        //alert("medical saved");
        setChildActiveTab('')
      }
    } else if (activeTab === 'COG' && childActiveTab === 'GL') {
      if (isMount) {
        COGSubTabRef.current.SaveGOAL()
        //alert("medical saved");
        setChildActiveTab('')
      }
    }

    // //FEED handle the auto save here
    if (activeTab === 'FEED' && childActiveTab === 'AS') {
      if (isMount) {
        FEEDSubTabRef.current.SaveAss()
        setChildActiveTab('')
      }
    } else if (activeTab === 'FEED' && childActiveTab === 'NM') {
      if (isMount) {
        FEEDSubTabRef.current.SaveNOMS()
        //alert("medical saved");
        setChildActiveTab('')
      }
    } else if (activeTab === 'FEED' && childActiveTab === 'GL') {
      if (isMount) {
        FEEDSubTabRef.current.SaveGOAL()
        //alert("medical saved");
        setChildActiveTab('')
      }
    }

    //VOIC handle the auto save here
    if (activeTab === 'VOIC' && childActiveTab === 'AS') {
      if (isMount) {
        VOICSubTabRef.current.SaveAss()
        setChildActiveTab('')
      }
    } else if (activeTab === 'VOIC' && childActiveTab === 'NM') {
      if (isMount) {
        VOICSubTabRef.current.SaveNOMS()
        setChildActiveTab('')
      }
    } else if (activeTab === 'VOIC' && childActiveTab === 'GL') {
      if (isMount) {
        VOICSubTabRef.current.SaveGOAL()
        setChildActiveTab('')
      }
    }

    //SWAL handle the auto save here
    if (activeTab === 'SWAL' && childActiveTab === 'AS') {
      if (isMount) {
        SWALSubTabRef.current.SaveAss()
        setChildActiveTab('')
      }
    } else if (activeTab === 'SWAL' && childActiveTab === 'NM') {
      if (isMount) {
        SWALSubTabRef.current.SaveNOMS()
        setChildActiveTab('')
      }
    } else if (activeTab === 'SWAL' && childActiveTab === 'GL') {
      if (isMount) {
        SWALSubTabRef.current.SaveGOAL()
        setChildActiveTab('')
      }
    }

    //FLU handle the auto save here
    if (activeTab === 'FLU' && childActiveTab === 'AS') {
      if (isMount) {
        FLUSubTabRef.current.SaveAss()
        setChildActiveTab('')
      }
    } else if (activeTab === 'FLU' && childActiveTab === 'NM') {
      if (isMount) {
        FLUSubTabRef.current.SaveNOMS()
        setChildActiveTab('')
      }
    } else if (activeTab === 'FLU' && childActiveTab === 'GL') {
      if (isMount) {
        FLUSubTabRef.current.SaveGOAL()
        setChildActiveTab('')
      }
    }

    //FLU handle the auto save here
    if (activeTab === 'PRAG' && childActiveTab === 'AS') {
      if (isMount) {
        PRAGSubTabRef.current.SaveAss()
        setChildActiveTab('')
      }
    } else if (activeTab === 'PRAG' && childActiveTab === 'NM') {
      if (isMount) {
        PRAGSubTabRef.current.SaveNOMS()
        setChildActiveTab('')
      }
    } else if (activeTab === 'PRAG' && childActiveTab === 'GL') {
      if (isMount) {
        PRAGSubTabRef.current.SaveGOAL()
        setChildActiveTab('')
      }
    }

    //AAC handle the auto save here
    if (activeTab === 'AAC' && childActiveTab === 'AS') {
      if (isMount) {
        AACSubTabRef.current.SaveAss()
        setChildActiveTab('')
      }
    } else if (activeTab === 'AAC' && childActiveTab === 'NM') {
      if (isMount) {
        AACSubTabRef.current.SaveNOMS()
        setChildActiveTab('')
      }
    } else if (activeTab === 'AAC' && childActiveTab === 'GL') {
      if (isMount) {
        AACSubTabRef.current.SaveGOAL()
        setChildActiveTab('')
      }
    }

    if (activeTab === 'POC') {
      POCRef.current.handleButtonClick(false)
    } else if (activeTab === 'SN') {
      //alert("snsn act");
      SoapNoteUIRef.current.handleButtonClick(false)
    } else if (activeTab === 'PN') {
      //alert("PNNN active");
      ProgressNoteUIRef.current.handleButtonClick(false)
    } else if (activeTab === 'DS') {
      DischargeSummaryUIRef.current.handleButtonClick(false)
    } else if (activeTab === 'NM') {
      IntNomsRef.current.handleButtonClick(false)
    }

    setActiveKey(eventKey)
    handleTabChange(eventKey)
  }
  const handleChildTabChange = (childTab) => {
    setChildActiveTab(childTab)
  }
  return (
    <div style={{ display: 'block', width: max, padding: 10 }}>
      {/* <div> <button
        style={{ width: '150px'}}
        className='btn btn-primary rounded-pill'
      >
        <b>Signlock</b>
      </button></div>
  //<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
      {/* {isProgressNote?"true":"false"} */}
      <Tabs activeKey={activeKey} onSelect={handleTabSelect}>
        {visitType !== null && (visitType === 1 || visitType === 3) && (
          <Tab
            eventKey='CHC'
            title={isAdult ? 'Case History-Adult' : 'Case History-Child'}
          >
            {activeKey === 'CHC' && (
              <TabContentWithBorder>
                <CasehistorysubTab
                  onTabChange={handleChildTabChange}
                  isAdult={isAdult}
                  ref={caseHistorySubtabRef}
                  triggerRefresh={triggerRefresh}
                />
              </TabContentWithBorder>
            )}
          </Tab>
        )}{' '}
        {tabVisibility.INT &&
          visitType !== null &&
          (visitType === 1 || visitType === 3) && (
            <Tab eventKey='INT' title='Speech Intelligibility'>
              <>
                {activeKey === 'INT' && (
                  <TabContentWithBorder>
                    <SpeechIntSubTab
                      isAdult={isAdult}
                      onTabChange={handleChildTabChange}
                      ref={SpeechIntSubTabRef}
                    />
                  </TabContentWithBorder>
                )}
              </>
            </Tab>
          )}
        {tabVisibility.SLC &&
          visitType !== null &&
          (visitType === 1 || visitType === 3) && (
            <Tab eventKey='SLC' title='Language Comprehension'>
              <>
                {activeKey === 'SLC' && (
                  <TabContentWithBorder>
                    <LanguageTabUI
                      isAdult={isAdult}
                      onTabChange={handleChildTabChange}
                      ref={SLCSubTabRef}
                    />
                  </TabContentWithBorder>
                )}
              </>
            </Tab>
          )}
        {tabVisibility.SLE &&
          visitType !== null &&
          (visitType === 1 || visitType === 3) && (
            <Tab eventKey='SLE' title='Language Expression'>
              <>
                {activeKey === 'SLE' && (
                  <TabContentWithBorder>
                    <LanguageExpression
                      isAdult={isAdult}
                      onTabChange={handleChildTabChange}
                      ref={SLESubTabRef}
                    />
                  </TabContentWithBorder>
                )}
              </>
            </Tab>
          )}
        {tabVisibility.COG &&
          visitType !== null &&
          (visitType === 1 || visitType === 3) && (
            <Tab eventKey='COG' title='Cognition'>
              <>
                {activeKey === 'COG' && (
                  <TabContentWithBorder>
                    <CognitionTabUI
                      isAdult={isAdult}
                      onTabChange={handleChildTabChange}
                      ref={COGSubTabRef}
                    />
                  </TabContentWithBorder>
                )}
              </>
            </Tab>
          )}
        {tabVisibility.SWAL &&
          visitType !== null &&
          (visitType === 1 || visitType === 3) && (
            <Tab eventKey='SWAL' title='Swallowing'>
              <>
                {activeKey === 'SWAL' && (
                  <TabContentWithBorder>
                    <SwallowingTabUI
                      isAdult={isAdult}
                      onTabChange={handleChildTabChange}
                      ref={SWALSubTabRef}
                    />
                  </TabContentWithBorder>
                )}
              </>
            </Tab>
          )}
        {tabVisibility.FEED &&
          visitType !== null &&
          (visitType === 1 || visitType === 3) && (
            <Tab eventKey='FEED' title='Feeding'>
              <>
                {activeKey === 'FEED' && (
                  <TabContentWithBorder>
                    <FeedingTabUI
                      isAdult={isAdult}
                      onTabChange={handleChildTabChange}
                      ref={FEEDSubTabRef}
                    />
                  </TabContentWithBorder>
                )}
              </>
            </Tab>
          )}
        {tabVisibility.VOIC &&
          visitType !== null &&
          (visitType === 1 || visitType === 3) && (
            <Tab eventKey='VOIC' title='Voice'>
              <>
                {activeKey === 'VOIC' && (
                  <TabContentWithBorder>
                    <VoiceTabUI
                      isAdult={isAdult}
                      onTabChange={handleChildTabChange}
                      ref={VOICSubTabRef}
                    />
                  </TabContentWithBorder>
                )}
              </>
            </Tab>
          )}
        {tabVisibility.FLU &&
          visitType !== null &&
          (visitType === 1 || visitType === 3) && (
            <Tab eventKey='FLU' title='Fluency'>
              <>
                {activeKey === 'FLU' && (
                  <TabContentWithBorder>
                    <FluencyTabUI
                      isAdult={isAdult}
                      onTabChange={handleChildTabChange}
                      ref={FLUSubTabRef}
                    />
                  </TabContentWithBorder>
                )}
              </>
            </Tab>
          )}
        {tabVisibility.PRAG &&
          visitType !== null &&
          (visitType === 1 || visitType === 3) && (
            <Tab eventKey='PRAG' title='Pragmatic'>
              <>
                {activeKey === 'PRAG' && (
                  <TabContentWithBorder>
                    <PragmaticTabUI
                      isAdult={isAdult}
                      onTabChange={handleChildTabChange}
                      ref={PRAGSubTabRef}
                    />
                  </TabContentWithBorder>
                )}
              </>
            </Tab>
          )}
        {tabVisibility.AAC &&
          visitType !== null &&
          (visitType === 1 || visitType === 3) && (
            <Tab eventKey='AAC' title='AAC'>
              <>
                {activeKey === 'AAC' && (
                  <TabContentWithBorder>
                    <AACTabUI
                      isAdult={isAdult}
                      onTabChange={handleChildTabChange}
                      ref={AACSubTabRef}
                    />
                  </TabContentWithBorder>
                )}
              </>
            </Tab>
          )}
        {visitType !== null && (visitType === 1 || visitType === 3) && (
          <Tab eventKey='POC' title='Plan of Care'>
            {activeKey === 'POC' && (
              <TabContentWithBorder>
                <Suspense fallback={<div>Loading...</div>}>
                  <POCare
                    ref={POCRef}
                    onDataFetched={() => setActiveKey('POC')}
                    refreshSignLock={refreshSignLock}
                    onIsClientDischarge={setIsClientDischarge}
                    //setIsClientDischarge
                  />
                </Suspense>
                {/* <POCare
                  ref={POCRef}
                  onDataFetched={() => setActiveKey('POC')}
                  refreshSignLock={refreshSignLock}
                /> */}
              </TabContentWithBorder>
            )}
          </Tab>
        )}
        {visitType !== null && (visitType === 2 || visitType === 4) && (
          <Tab eventKey='SN' title='SOAP Note'>
            <TabContentWithBorder>
              {activeKey === 'SN' && (
                <SoapNoteUI
                  ref={SoapNoteUIRef}
                  triggerDisplayNote={triggerDisplayNote}
                />
              )}
            </TabContentWithBorder>
          </Tab>
        )}
        {visitType === 2 &&
          // eslint-disable-next-line eqeqeq
          (DisplayTabNote === 1 ||
            DisplayTabNote == 1 ||
            isProgressNote === true ||
            NoOfDays >= 30 ||
            FollowUpVisitCount >= 9) && (
            <Tab eventKey='PN' title='Progress Note' id='tabProgressNotes'>
              <TabContentWithBorder>
                {activeKey === 'PN' && (
                  <ProgressNoteUI
                    ref={ProgressNoteUIRef}
                    refreshSignLock={refreshSignLock}
                  />
                )}
              </TabContentWithBorder>
            </Tab>
          )}
        {(visitType === 4 ||
          IsClientDischarge === true ||
          DisplayTabNote === 2 ||
          (visitType === 2 && DisplayTabNote === 2)) && (
          <Tab eventKey='DS' title='Discharge Summary'>
            <TabContentWithBorder>
              {activeKey === 'DS' && (
                <DischargeSummaryUI
                  ref={DischargeSummaryUIRef}
                  refreshSignLock={refreshSignLock}
                />
              )}
            </TabContentWithBorder>
          </Tab>
        )}
        {((visitType !== null && visitType === 4) ||
          DisplayTabNote === 2 ||
          (visitType === 2 && DisplayTabNote === 2)) && (
          <Tab eventKey='NM' title='NOMS'>
            <TabContentWithBorder>
              {activeKey === 'NM' && <IntNoms ref={IntNomsRef} />}
            </TabContentWithBorder>
          </Tab>
        )}
      </Tabs>{' '}
    </div>
  )
}
