import React, { useState, useEffect } from 'react'

export const NomsComp = ({ defaultRow, onRowSelected, visitId }) => {
  const [gridData, setGridData] = useState([
    {
      Discharge_NomsId: 0,
      ResponseType: 0,
      GroupType: 1,
      FCMType: 1,
      VisitId: visitId,
      QuestionCode: null,
      Answer: '0-25% of the time',
      Question:
        'produce speech that is distracting or sound unusual to the listener? ',
      IsLongTermGoal: false,
      RowId: 1,
      CreatedBy: null,
      UpdatedBy: null
    },
    {
      Discharge_NomsId: 0,
      ResponseType: 0,
      GroupType: 1,
      FCMType: 1,
      VisitId: visitId,
      QuestionCode: null,
      Answer: '0-25% of the time',
      Question:
        'produce words/short phrases that are intelligible to FAMILIAR listeners? ',
      IsLongTermGoal: false,
      RowId: 2,
      CreatedBy: null,
      UpdatedBy: null
    },
    {
      Discharge_NomsId: 0,
      ResponseType: 0,
      GroupType: 1,
      FCMType: 1,
      VisitId: visitId,
      QuestionCode: null,
      Answer: '0-25% of the time',
      Question:
        'produce connected speech that is intelligible to FAMILIAR listeners? ',
      IsLongTermGoal: false,
      RowId: 3,
      CreatedBy: null,
      UpdatedBy: null
    },
    {
      Discharge_NomsId: 0,
      ResponseType: 0,
      GroupType: 1,
      FCMType: 1,
      VisitId: visitId,
      QuestionCode: null,
      Answer: '0-25% of the time',
      Question:
        'produce words/short phrases that are intelligible to UNFAMILIAR listeners? ',
      IsLongTermGoal: false,
      RowId: 4,
      CreatedBy: null,
      UpdatedBy: null
    },
    {
      Discharge_NomsId: 0,
      ResponseType: 0,
      GroupType: 1,
      FCMType: 1,
      VisitId: visitId,
      QuestionCode: null,
      Answer: '0-25% of the time',
      Question:
        'produce connected speech that is intelligible to UNFAMILIAR listeners?',
      IsLongTermGoal: false,
      RowId: 5,
      CreatedBy: null,
      UpdatedBy: null
    },
    {
      Discharge_NomsId: 0,
      ResponseType: 0,
      GroupType: 1,
      FCMType: 1,
      VisitId: visitId,
      QuestionCode: null,
      Answer: '0-25% of the time',
      Question:
        'participate in communication exchanges WITHOUT additional assistance from communication partner (no more than would be expected for chronological age)?',
      IsLongTermGoal: false,
      RowId: 6,
      CreatedBy: null,
      UpdatedBy: null
    },
  ])
  const [selectedRow, setSelectedRow] = useState(null)
  const [defaultRowProcessed, setDefaultRowProcessed] = useState(false)
  const [dropdownOptions, setDropdownOptions] = useState([
    { value: '0-25% of the time', text: '0-25% of the time' },
    { value: '26-49% of the time', text: '26-49% of the time' },
    { value: '50-75% of the time', text: '50-75% of the time' },
    { value: '76-90% of the time', text: '76-90% of the time' },
    { value: '91-100% of the time', text: '91-100% of the time' },
  ])

  useEffect(() => {
    if (!defaultRowProcessed && defaultRow && defaultRow.length > 0) {
      const updatedData = gridData.map((row) => {
        const matchingDefaultRow = defaultRow.find(
          (defaultRowItem) =>
            String(defaultRowItem.RowId) === String(row.RowId),
        )

        if (matchingDefaultRow) {
          return {
            ...row,
            Discharge_NomsId: matchingDefaultRow.Discharge_NomsId,
            Answer: matchingDefaultRow.Answer,
            QuestionCode: matchingDefaultRow.QuestionCode,
            //IsLongTermGoal:matchingDefaultRow.IsLongTermGoal
          }
        }

        return row
      })
      //console.log(JSON.stringify(updatedData));
      setGridData(updatedData)
      setSelectedRow(null) // Clear the selected row, as multiple rows might have changed
      onRowSelected(updatedData) // Notify parent about the updated rows
      setDefaultRowProcessed(true)
    }
  }, [defaultRow, defaultRowProcessed])

  const handleDropdownChange = (id, value) => {
    const selectedValue = value.trim() === '' ? dropdownOptions[0].value : value
    const updatedData = gridData.map((row) => {
      if (row.RowId === id) {
        return { ...row, Answer: selectedValue }
      }
      return row
    })
    setGridData(updatedData)
    setSelectedRow(updatedData.find((row) => row.RowId === id))
    onRowSelected(updatedData)
  }

  //   const handleCheckboxChange = (id, isChecked) => {
  //     //alert(isChecked);
  //     const updatedData = gridData.map((row) => {
  //       if (row.RowId === id) {
  //         return { ...row, IsLongTermGoal: isChecked };
  //       }
  //       return { ...row, IsLongTermGoal: false };
  //     });
  //     setGridData(updatedData);

  //     onRowSelected(updatedData);
  //   };

  const getDropdownOptionsForRow = () => {
    //alert(JSON.stringify(dropdownOptions));
    return dropdownOptions
  }

  return (
    <table className='table table-striped'>
      <thead>
        <tr>
          <th style={{ width: '2%' }}>#</th>
          <th style={{ width: '75%' }}>How often does the individual… </th>
          <th style={{ width: '15%' }}>Response values</th>
        </tr>
      </thead>
      <tbody>
        {gridData.map((row) => (
          <tr key={row.RowId}>
            <td>{row.RowId}</td>
            <td>{row.Question}</td>
            <td>
              <select
                className='form-select form-select-sm'
                value={row.Answer}
                onChange={(event) =>
                  handleDropdownChange(row.RowId, event.target.value)
                }
              >
                {getDropdownOptionsForRow().map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
