import React, { useState, useEffect, useContext, useRef } from 'react'
import { PDFViewer, pdf } from '@react-pdf/renderer'
import { useUser } from '../../Provider/UserContext'
import { VisitProvider } from '../../Provider/Visitprovider'
import { Variables } from '../../Variables'
import PdfGenerateNotes from './PdfGenerateNotes'

const apiUrl = Variables.API_URL

const ProgNotesPDFDownload = ({ pSummaryData = null, onPdfGenerationComplete }) => {
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false)
  const { userDetails } = useUser()
  const VisitId = useContext(VisitProvider)
  const [pdfContent, setPdfContent] = useState(null)
  const [pdfFilename, setPdfFilename] = useState(`${VisitId}.pdf`)
  const ClinicalReport = useRef({
    ServiceLine: 0,
    VisitId: 0,
    CompanyId: 0,
    CreatedBy: 0,
  })
  const [clientdata, setClientdata] = useState(null)
  const [FullName, setFullName] = useState(null)
  const [FirstName, setFirstName] = useState('')
  const [HeOrShe, setHeOrShe] = useState('')
  const [hisHer, sethisHer] = useState('')

  useEffect(() => {
    if (pSummaryData && userDetails) {
      handleUserDetails(userDetails)
      //GeneratePDF()
    }
  }, [pSummaryData, userDetails])

  useEffect(() => {
    if (FullName && FirstName && hisHer && HeOrShe) {
      GeneratePDF()
    }
  }, [FullName, FirstName, hisHer, HeOrShe])

  const handleUserDetails = (userDetails) => {
    ClinicalReport.current.CompanyId = userDetails.CompanyId
    ClinicalReport.current.VisitId = VisitId
    ClinicalReport.current.CreatedBy = userDetails.TherapistId
    ClinicalReport.current.ServiceLine = 1

    setClientdata(userDetails)
    setHeOrShe(userDetails.Sex === 1 ? 'He' : 'She')
    sethisHer(userDetails.Sex === 1 ? 'his' : 'her')
    setFullName(`${userDetails.LastName}, ${userDetails.FirstName}`)
    setFirstName(userDetails.FirstName)
  }

  const GeneratePDF = () => {
    if (pSummaryData) {
      const doc = (
        <PdfGenerateNotes
          userDetails={userDetails}
          FullName={FullName}
          FirstName={FirstName}
          hisHer={hisHer}
          HeOrShe={HeOrShe}
          clientdata={clientdata}
          VisitId={VisitId}
          pSummaryData={pSummaryData}
        />
      )
      setPdfContent(pdf(doc))
    }
  }

  const uploadPDF = async () => {
    try {
      setIsGeneratingPDF(true)
      const blob = await pdfContent.toBlob()
      const formData = new FormData()

      formData.append('pdfFile', blob, pdfFilename)
      formData.append('clinicalreport', JSON.stringify(ClinicalReport.current))

      const response = await fetch(`${apiUrl}/POC/UploadPDF`, {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        console.log('The PDF file has been saved to the drive successfully!')
        onPdfGenerationComplete(true)
      } else {
        onPdfGenerationComplete(false)
      }
    } catch (error) {
      console.error('Error uploading PDF:', error)
      onPdfGenerationComplete(false)
    } finally {
      setIsGeneratingPDF(false)
    }
  }

  useEffect(() => {
    if (pdfContent) {
      setTimeout(() => {
        if (!isGeneratingPDF) {
          uploadPDF()
        }
      }, 10000) // 10 seconds delay
    }
  }, [pdfContent])

  return (
    <>
      {/* UI Elements can be added here if necessary */}
    </>
  )
}

export default ProgNotesPDFDownload
