export const reportStyle = {
  pageSpace: {
    marginLeft: '30px',
    marginRight: '30px',
  },
  headerTextSpace: {
    width: 40, // Adjust the width to control spacing
    fontSize: 8,
    fontWeight: 'bold',
  },
  header: {
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    marginBottom: 20,

    paddingTop: 10,
    paddingLeft: 50,
  },
  image: {
    paddingTop: 1,
    width: 120,
    height: 30,
  },
  footer: {
    position: 'absolute',
    bottom: 8,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 50,
    paddingRight: 50,

    //  border:3,
    //  borderColor:"red"
  },
  footerTextLeft: {
    fontWeight: 'bold',
    marginTop: 5,
    fontSize: 9,
    color: 'grey',
  },
  footerTextRight: {
    fontWeight: 'bold',
    marginTop: 5,
    fontSize: 9,
    color: 'grey',
  },
  headerTextContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: -30,
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 30,
  },
  headerTextContainerText: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 10,
    fontSize: 9,
    marginTop: -15,
    fontWeight: 'bold',
    marginLeft: 70,
  },
  headerText: {
    fontSize: 9,
    fontWeight: 'bold',
  },
  gridContainer: {
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'stretch',
    marginLeft: 20,
    marginBottom: 2,
  },
  gridRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'left',
    marginBottom: 0,
  },
  gridCell: {
    flex: 1,
    justifyContent: 'left',
    alignItems: 'left',
    borderWidth: 0,
    borderColor: '#000',
    padding: 2,
    fontSize: 9,
  },
  TextHeaderTop: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 10,
    marginLeft: 20,
  },
  TextHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 5,
    marginLeft: 20,
    marginRight: 20,
  },
  TextHeaderText: {
    fontSize: 9,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderColor: '#000',
  },

  TextSubHeader: {
    fontSize: 8,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderColor: '#000',
    fontStyle: 'italic',
  },

  paragraph: {
    marginTop: 3,
    marginLeft: 20,
    marginRight: 20,
    fontSize: 8,
    lineHeight: 1.2,
    textAlign: 'left',
  },
  // paragraphbottom: {
  //   marginTop: 3,
  //   marginLeft: 20,
  //   marginRight: 20,
  //   fontSize: 8,
  //   lineHeight: 1.2,
  //   textAlign: 'left',
  //   marginBottom: 35,
  // },
  pageNumber: {
    fontWeight: 'bold',
    marginTop: 5,
    flex: 1,
    marginLeft: 40,
    //textAlign: 'center',
    fontSize: 9,
    color: 'grey',
  },
  page: {
    padding: 10,
  },
  tableT: {
    borderWidth: 0.5,
    borderColor: '#ddd',
    marginTop: 2,
    marginLeft: 20,
    marginRight: 20,
  },
  headerRowT: {
    flexDirection: 'row',
    backgroundColor: '#ddd',
    borderBottomWidth:0.5,
    borderBottomColor: '#ddd',
  },
  headerCellT: {
    flex: 1,
    padding: 2,
    fontWeight: 'bold',
    borderRightWidth:0.5,
    borderRightColor: '#ddd',
    textAlign: 'center',fontSize: 8,
  },
  dataRowT: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#ddd',
  },
  dataCellT: {
    flex: 1,
    padding: 2,
    borderRightWidth: 0.5,
    borderRightColor: '#ddd',
    textAlign: 'center',
    fontSize: 8,
  },

  tblheaderRow: {
    flexDirection: 'row',
    borderBottom: '1px solid #e1e1e1', // Light gray border
    backgroundColor: '#e1e1e1', // Very light gray background
    padding: '2px', // Padding for cells
  },
  tblheaderCell: {
    fontWeight: 'bold',
    fontSize: 8,
    width: '10%',
  },
  tbldataRow: {
    flexDirection: 'row',
    borderBottom: '1px solid #FAFAFA',
    padding: '2px', // Padding for cells
  },
  tbldataCell: {
    flex: 1,
    fontSize: '8pt', // Font size 11
  },
  tbldataCellAssessment: {
    fontSize: '8pt', // Font size 11
    width: '70%',
  },
  tbldataCellNo: {
    fontSize: '8pt', // Font size 11
    width: '10%',
  },

  container: {
    paddingTop: '0px',
    paddingLeft: '20px', // Padding from the left
    paddingRight: '20px', // Padding from the right
  },
  soundContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: '20px', // Padding from the left
    paddingRight: '20px',
  },

  cell: {
    width: '25%',
    padding: 1,
    fontSize: '9pt', // Font size 11
  },
  customText: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 8,
  },
  boldText: {
    fontFamily: 'Roboto-Bold',
    fontWeight: 'bold',
    fontSize: 9,
  },
  doulosText: {
    fontFamily: 'Doulos SIL',
    fontWeight: 'bold',
    fontSize: 9,
  },
  tblnoms: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    // paddingLeft: "10px", // Padding from the left
    // paddingRight: "10px",
  },

  dataRownoms: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '0px solid #FAFAFA',
    padding: 0,
  },
  indexCell: {
    width: '2%',
  },
  questionCell: {
    width: '82%',
  },
  answerCell: {
    width: '15%',
  },
  questionCell97: {
    width: '98%',
  },
  fcmcolumn: {
    width: '50%', // Adjust as needed
    //padding:0,
    fontSize: 9,
    marginTop: 1, // Adjust as needed
    marginBottom: 1,
  },

  footerbox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    marginBottom: '10px',
    borderTop: '0px solid #ccc',
    flexDirection: 'row',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  signatureBox: {
    flex: 1, // Allow the boxes to take equal width
    border: '0px solid #000', // Add border for visualization
    padding: '0px',
    fontSize: 10,
    textAlign: 'left', // Align text within the box to the left
  },

  signatureBoxRight: {
    flex: 1, // Allow the boxes to take equal width
    border: '0px solid #000', // Add border for visualization
    padding: '0px',
    fontSize: 10,
    textAlign: 'right', // Align text within the box to the right
  },
}

export const SoapNoteStyle = {
  header: {
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    marginBottom: 20,
    paddingTop: 10,
  },
  image: {
    width: 120,
    height: 30,
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  footerTextLeft: {
    fontSize: 10,
  },
  footerTextRight: {
    fontSize: 10,
  },
  headerTextContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: -30,
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 30,
  },
  headerTextContainerText: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
    fontSize: 8,
    marginTop: -15,
    fontWeight: 'bold',
    paddingLeft: 170,
    paddingRight: 20,
  },
  headerText: {
    fontSize: 8,
    fontWeight: 'bold',
  },
  headerTextPart: {
    marginRight: 20,
    paddingRight: 20,
  },
  headerTextSpace: {
    width: 40, // Adjust the width to control spacing
    fontSize: 8,
    fontWeight: 'bold',
  },
  gridContainer: {
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'stretch',
    marginLeft: 20,
    paddingTop: -25,
    marginBottom: 5,
  },
  gridRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'left',
    marginBottom: 0,
  },
  gridCell: {
    flex: 1,
    justifyContent: 'left',
    alignItems: 'left',
    borderWidth: 0,
    borderColor: '#000',
    padding: 2,
    fontSize: 9,
  },
  TextHeaderTop: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 10,
    marginLeft: 10,
  },
  TextHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 5,
    marginLeft: 20,
  },
  TextHeaderText: {
    fontSize: 9,
    fontWeight: 'bold',
    borderBottom: 1,
    borderColor: '#000',
  },
  paragraph: {
    marginTop: 5,
    marginLeft: 30,
    marginRight: 30,
    fontSize: 8,
    lineHeight: 1.5,
    textAlign: 'left',
  },
  pageNumber: {
    flex: 1,
    marginLeft: 150,
    textAlign: 'center',
    fontSize: 10,
  },
  page: {
    padding: 10,
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: '10px', // Padding from the left
    paddingRight: '10px',
  },
  cell: {
    width: '25%',
    padding: 1,
    fontSize: '9pt', // Font size 11
  },
  customText: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 9,
  },
  boldText: {
    fontFamily: 'Roboto-Bold',
    fontWeight: 'bold',
    fontSize: 9,
  },
  doulosText: {
    fontFamily: 'Doulos SIL',
    fontWeight: 'bold',
    fontSize: 9,
  },
  containerQ: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    // paddingLeft: "10px", // Padding from the left
    // paddingRight: "10px",
  },
  dataRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '0px solid #FAFAFA',
    padding: 0,
  },
  indexCell: {
    width: '3%',
    fontSize: 9,
  },
  questionCell: {
    width: '100%',
    fontSize: 9,
  },
  answerCell: {
    width: '15%',
    fontSize: 9,
  },
  fcmcolumn: {
    width: '50%', // Adjust as needed
    //padding:0,
    fontSize: 9,
    marginTop: 1, // Adjust as needed
    marginBottom: 1,
  },
  paragraph: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: 9,
  },
  footerbox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    marginBottom: '10px',
    borderTop: '0px solid #ccc',
    flexDirection: 'row',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    marginTop: 50,
    paddingTop: 100,
  },
  signatureBox: {
    display: 'flex',
    justifyContent: 'space-between',
    // flex: 1, // Allow the boxes to take equal width
    border: '0px solid #000', // Add border for visualization
    marginTop: 10,
    marginBottom:5,
    marginLeft: 20,
    // padding: 100,
    fontSize: 10,
    textAlign: 'left', // Align text within the box to the left
  },

  signatureBoxRight: {
    flex: 1, // Allow the boxes to take equal width
    border: '0px solid #000', // Add border for visualization
    padding: '0px',
    fontSize: 10,
    textAlign: 'right', // Align text within the box to the right
  },
}

export const PNNoteStyle = {
  header: {
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    marginBottom: 20,
    paddingTop: 10,
  },
  image: {
    width: 120,
    height: 30,
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
  },
  footerTextLeft: {
    fontSize: 10,
  },
  footerTextRight: {
    fontSize: 10,
  },
  headerTextContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: -30,
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 30,
  },
  headerTextContainerText: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
    fontSize: 8,
    marginTop: -15,
    fontWeight: 'bold',
    paddingLeft: 200,
    paddingRight: 20,
  },
  headerText: {
    fontSize: 8,
    fontWeight: 'bold',
  },
  headerTextPart: {
    marginRight: 20,
    paddingRight: 20,
  },
  headerTextSpace: {
    width: 40, // Adjust the width to control spacing
    fontSize: 8,
    fontWeight: 'bold',
  },
  gridContainer: {
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'stretch',
    marginLeft: 20,
  },
  gridRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'left',
    marginBottom: 0,
  },
  gridCell: {
    flex: 1,
    justifyContent: 'left',
    alignItems: 'left',
    borderWidth: 0,
    borderColor: '#000',
    padding: 2,
    fontSize: 8,
  },
  TextHeaderTop: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 10,
    marginLeft: 10,
  },
  TextHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 5,
    marginLeft: 20,
  },
  TextHeaderText: {
    fontSize: 9,
    fontWeight: 'bold',
    borderBottom: 1,
    borderColor: '#000',
  },
  SubHeaderText: {
    fontSize: 9,
    fontWeight: 'bold',
    borderBottom: 0,
    borderColor: '#000',
  },
  paragraph: {
    marginTop: 5,
    marginLeft: 20,
    marginRight: 20,
    fontSize: 8,
    lineHeight: 1.5,
    textAlign: 'left',
  },
  pageNumber: {
    flex: 1,
    marginLeft: 150,
    textAlign: 'center',
    fontSize: 10,
  },
}
