import axios from 'axios'
import { Variables } from '../../Variables'

const apiUrl = Variables.API_URL

export class CPTBillingCodeService {
  constructor() {
    this.CPTBillingCodeId = 0
    this.VisitId = 0
    this.VisitType = 0
    this.RowId = 0
    this.CPTCode = null
    this.CPTCodeDescription = null
    this.Units = null
  }

  saveData(formData) {
    return new Promise((resolve, reject) => {
      const apiCalls = []
      apiCalls.push(axios.post(apiUrl + 'POC/InsertCPTBillingCode', formData))

      if (apiCalls.length === 0) {
        reject(new Error('No promises to resolve.'))
        return
      }

      Promise.all(apiCalls)
        .then((responses) => {
          resolve(responses)
        })
        .catch((error) => {
          console.log('Failed to save data. Please try again.', error)
          reject(error)
        })
    })
  }

  insertCodeBillingtoCore(formData, visitId, therapistId, IsCPTCodeBase) {
    const apiUrl = Variables.USER_API_URL
    const billingDTOs = formData.map((data) => ({
      cptCode: data.CPTCode,
      units: data.Units,
      isSplintCodes: false,
    }))

    const billingCharges = {
      visitId: visitId,
      billingDTOs: IsCPTCodeBase ? billingDTOs : null,
      userId: therapistId,
      isSplintCodes: false,
    }
    //alert(JSON.stringify(billingCharges))
    //return
    return axios
      .post(`${apiUrl}SLPBilling/CopyPayorRateTocharges`, billingCharges)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error inserting insertCodeBillingtoCore data:', error)
        throw error
      })
  }

  getEpisodeData(clientId) {
    const apiUrl = Variables.USER_API_URL
    return axios
      .get(
        apiUrl +
          'Episode/GetEpisodeByServiceLine?clientId=' +
          clientId +
          '&treatmentTypeId=1',
      )
      .then((response) => {
        const data = response.data
        if (response.status === 200) {
          if (data) {
            return data.episodeid
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching data CaseHistory/DiagnosisCode/:', error)
        throw error
      })
  }

  updateNoMoreAuthRequest(data, clientId) {
    // Call the getEpisodeData function and handle its Promise
    return this.getEpisodeData(clientId)
      .then((episodeId) => {
        data.EpisodeId = episodeId

        const apiUrl = Variables.USER_API_URL

        return axios
          .put(`${apiUrl}Episode/UpdateNoMoreAuthRequest`, data)
          .then((response) => response.data)
          .catch((error) => {
            console.error(
              'Error inserting UpdateNoMoreAuthRequest data:',
              error,
            )
            throw error
          })
      })
      .catch((error) => {
        console.error('Error getting episode data:', error)
        throw error
      })
  }

  getCPTBillingCode(VisitId) {
    return axios
      .get(apiUrl + 'POC/GetCPTBillingCode/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        if (data && Array.isArray(data)) {
          const processedData = data.map((item) => {
            return {
              CPTBillingCodeId: item.CPTBillingCodeId,
              VisitId: item.VisitId,
              VisitType: item.VisitType,
              RowId: item.RowId,
              CPTCode: item.CPTCode,
              CPTCodeDescription: item.CPTCodeDescription,
              Units: item.Units,
            }
          })
          return processedData
        }
      })
      .catch((error) => console.log('Error fetching data:', error))
  }

  deleteCode(id) {
    return axios
      .delete(apiUrl + 'POC/DeleteBillingCode/' + id)
      .then((response) => {
        return response.data // Return the data if needed
      })
      .catch((error) => {
        console.log('Error deleting data:', error)
      })
  }
}
