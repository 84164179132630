import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Delete, Add } from '../../Icon/DeleteIcon'
import { AsyncAutoComplete } from '../../Controls/MultiSelectDropdown'
import { DDList } from '../../Controls/DDList'

export const CPTCodeBillingUI = ({
  onRowAdded,
  onRowDeleted,
  onUpdatedRows,
  apiData,
  VisitType,
  cptCodeList,
  VisitId,
  IsCPTCodeBase,
  RateTypeId,
  defaultBilingCode,
  IsChangeTracking,
  IsSignLock = false,
}) => {
  const [rowIdCounter, setRowIdCounter] = useState(1)
  const [rows, setRows] = useState([
    {
      RowId: rowIdCounter,
      CPTBillingCodeId: 0,
      VisitId: VisitId,
      VisitType: VisitType,
      CPTCode: defaultBilingCode,
      CPTCodeDescription: '',
      Units: 1,
      isDisabled: false,
    },
  ])

  const [isRequiredMessageShownCPT, setIsRequiredMessageShownCPT] =
    useState(false)
  const getCPTCodeLabel = (CPTCode) => {
    const cptCode = cptCodeList?.find(
      (cptCodeItem) => cptCodeItem.value === CPTCode.toString(),
    )
    // alert("filter : "+JSON.stringify(cptCodeList))
    return cptCode ? cptCode.label : ''
  }

  useEffect(() => {
    //alert(JSON.stringify(apiData))

    let initialData = []
    if (Array.isArray(apiData) && apiData.length > 0) {
      initialData = apiData.map((assOption) => ({
        ...assOption,
        CPTBillingCodeId: assOption.CPTBillingCodeId,
        RowId: assOption.RowId ?? rowIdCounter + initialData.length,
        VisitId: assOption.VisitId,
        VisitType: assOption.VisitType,
        CPTCode: assOption.CPTCode,
        Units: assOption.Units,
        CPTCodeDescription:
          assOption.CPTCodeDescription || getCPTCodeLabel(assOption.CPTCode),
        isDisabled: assOption.CPTCode === 0 ? false : true,
      }))
      //setRowIdCounter(rowIdCounter + initialData.length);
      setRows(initialData)
      //alert("if1")
      //console.log(JSON.stringify(cptCodeList))
    } else {
      setRows(rows)
      onUpdatedRows(rows)
    }
  }, [apiData])

  //const maxRowId = rows.reduce((maxId, row) => Math.max(maxId, row.RowId), 0);

  const handleAddRow = () => {
    const newRowId = rowIdCounter + 1
    setRowIdCounter(newRowId)

    const newRow = {
      RowId: newRowId,
      CPTBillingCodeId: 0,
      VisitId: VisitId,
      VisitType: VisitType,
      CPTCode: 0,
      CPTCodeDescription: '',
      Units: 1,
      isDisabled: false,
    }
    //console.log('New Row:', newRow)
    //console.log('ALL ROW:', rows)
    setRows((prevRows) => [...prevRows, newRow])
    onRowAdded(newRow)

    const updatedRowsArray = [...rows, newRow]
    onUpdatedRows(updatedRowsArray)
  }

  const handleDeleteRow = (id) => {
    if (IsSignLock) {
      alert("Record is sign and locked. Can't delete the record.")
      return
    }
    const confirmed = window.confirm(
      'Are you sure you want to delete this row?',
    )
    if (confirmed) {
      const deletedRow = rows.find((row) => row.RowId === id)
      const updatedRows = rows.filter((row) => row.RowId !== id)
      setRows(updatedRows)
      onUpdatedRows(updatedRows)
      onRowDeleted(deletedRow)
    }
  }

  const handleCptSelect = (rowId, selectedValue, columnName) => {
    if (selectedValue.value !== undefined) {
      const updatedRows = rows.map((row) => {
        if (row.RowId === rowId) {
          return {
            ...row,
            [columnName]: selectedValue.value,
            CPTCodeDescription: selectedValue.label,
            isDisabled: true,
          }
        }
        return row
      })
      setRows(updatedRows)
      onUpdatedRows(updatedRows)
    }
  }

  const handleSelect = (rowId, selectedValue, columnName) => {
    const updatedRows = rows.map((row) => {
      if (row.RowId === rowId) {
        return { ...row, [columnName]: selectedValue }
      }
      return row
    })
    //console.log('Updated Rows:', updatedRows)
    setRows(updatedRows)
    onUpdatedRows(updatedRows)
    IsChangeTracking(true)
  }

  const getFilteredCptCodes = (rowId) => {
    const selectedCptCodes = rows
      .filter((row) => row.RowId !== rowId)
      .map((row) => row.CPTCode)
    return cptCodeList?.filter(
      (cptCode) => !selectedCptCodes.includes(cptCode.value),
    )
  }

  return (
    <div>
      <table className='table'>
        <thead>
          <tr>
            <th>Billing Code(s):</th>
            <th>Units</th>
            <th>
              {rows.length < 4 && cptCodeList?.length !== rows.length && (
                <button onClick={handleAddRow}>
                  <Add />
                </button>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.RowId}>
              <td style={{ width: '70%' }}>
                <Form>
                  <Form.Group as={Row}>
                    <Col sm={12}>
                      <AsyncAutoComplete
                        fetchedData={getFilteredCptCodes(row.RowId)}
                        noOptionsMessage='No options found'
                        setIsRequiredMessageShown={setIsRequiredMessageShownCPT}
                        selectedValue={row.CPTCode}
                        defaultValue={row.CPTCode}
                        setSelectedValue={(selectedValue) =>
                          handleCptSelect(row.RowId, selectedValue, 'CPTCode')
                        }
                        isMultiSelect={false}
                        IsChangeTracking={IsChangeTracking}
                        isDisabled={row.isDisabled}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </td>
              <td style={{ width: '25%' }}>
                {typeof row.CPTCodeDescription === 'string' &&
                  row.CPTCodeDescription !== '' &&
                  !row.CPTCodeDescription.toLowerCase().includes('untimed') && (
                    <DDList
                      onSelect={(selectedValue) =>
                        handleSelect(row.RowId, selectedValue, 'Units')
                      }
                      defaultValue={row.Units}
                      ddType={35}
                    />
                  )}
                {/* {row.CPTCodeDescription !== '' &&
                  !row.CPTCodeDescription.toLowerCase().includes('untimed') && (
                    <DDList
                      onSelect={(selectedValue) =>
                        handleSelect(row.RowId, selectedValue, 'Units')
                      }
                      defaultValue={row.Units}
                      ddType={35}
                    />
                  )} */}
              </td>
              <td>
                {row.CPTCode !== defaultBilingCode && (
                  <button onClick={() => handleDeleteRow(row.RowId)}>
                    <Delete />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
