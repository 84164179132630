//Local Env API
// export const Variables = {
//   //API_URL:process.env.REACT_APP_API_URL,
//    //USER_API_URL: process.env.REACT_APP_USER_API_URL,
//   //REACT_APP_API_URL=https://sit-appapi.slptele.com/api/
// //REACT_APP_USER_API_URL=https://sit-stnotesapi.slptele.com/api/
// //REACT_APP_API_URL=https://sit-appapi.slptele.com/api/
// //REACT_APP_USER_API_URL=https://sit-stnotesapi.slptele.com/api/
//   API_URL: 'http://localhost:65270/api/',
//   USER_API_URL: 'http://localhost:50930/api/',
// }
//Test Env API
// export const Variables = {
//   API_URL: 'https://teststnotesapi.slptele.com/api/',
//   USER_API_URL: 'https://testapi.slptele.com/api/',
// }

//Test Env API NEW Test server
// export const Variables = {
//   API_URL: 'https://teststnotesapi.slptele.com/api/',
//   USER_API_URL: 'https://testapi.slptele.com/api/',
// }
// export const Variables = {
//   API_URL: 'https://sit-stnotesapi.slptele.com/api/',
//   USER_API_URL: 'https://sit-appapi.slptele.com/api/',
// }

// Production Env API
export const Variables = {
  API_URL: 'https://stnotesapi.slptele.com/api/',
  USER_API_URL: 'https://appapi.slptele.com/api/',
}
