import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Form } from 'react-bootstrap'
import {
  ComprehendsOptions,
  AnswerQuestionOptions,
  NotedLanguageOptions,
  WordUseOptions,
  PragmaticLanguageOptions,
  LanguageComplexityOptions,
  MemoryOptions,
  OrientationOptions,
  AttentionOptions,
  ExecutiveFunctioningOptions,
  PragmaticsOptions,
  SymptomsOptions,HistoryOptions,QualityResonanceOptions,DysfluencyOptions,PhysicalPresentationsOptions,ClutteringOptions,VerbalCommOptions,
  NonVerbalCommOptions,
  SocialInteractionOptions,PragVoiceOptions
} from '../data/options'

export function Checkboxlist({ showCheckboxList }) {
  const [checkboxes, setCheckboxes] = useState([])
  //alert(showCheckboxList);
  var options = []
  if (showCheckboxList == '1') {
    options = [
      { id: 1, label: 'Cognition' },
      { id: 2, label: 'Emergent Literacy' },
      { id: 3, label: 'Feeding' },
      { id: 4, label: 'Fluency' },
      { id: 5, label: 'Functional Reading' },
      { id: 6, label: 'Multi-Modal Functional Communication' },
      { id: 7, label: 'Pediatric Reading Comprehension' },
    ]
  } else if (showCheckboxList == '2') {
    options = [
      { id: 1, label: 'Pragmatics' },
      { id: 2, label: 'Speech Intelligibility' },
      { id: 3, label: 'Spoken Language Comprehension' },
      { id: 4, label: 'Spoken Language Expression' },
      { id: 5, label: 'Swallowing' },
      { id: 6, label: 'Voice' },
      { id: 7, label: 'Writting' },
    ]
  } else if (showCheckboxList == '3') {
    options = [
      { id: 1, label: 'p' },
      { id: 2, label: 'm' },
      { id: 3, label: 'n' },
      { id: 4, label: 'w' },
      { id: 5, label: 'h' },
      { id: 6, label: 'b' },
      { id: 7, label: 'g' },
      { id: 8, label: 'k' },
      { id: 9, label: 'd' },
      { id: 10, label: 'f' },
      { id: 11, label: 'ŋ' },
      { id: 12, label: 'j' },
      { id: 13, label: 'ʃ' },
    ]
  } else if (showCheckboxList == '4') {
    options = [
      { id: 1, label: 't' },
      { id: 2, label: 'tʃ' },
      { id: 3, label: 'l' },
      { id: 4, label: 'r' },
      { id: 5, label: 'ʤ' },
      { id: 6, label: 'θ' },
      { id: 7, label: 'v' },
      { id: 8, label: 's' },
      { id: 9, label: 'z' },
      { id: 10, label: 'δ' },
      { id: 11, label: 'L-blends' },
      { id: 12, label: 'R-blends' },
      { id: 13, label: 'S-blends' },
    ]
  } else {
    return null
  }

  const handleCheckboxChange = (optionId) => {
    const updatedCheckboxes = checkboxes.map((checkbox) => {
      if (checkbox.id === optionId) {
        return {
          ...checkbox,
          checked: !checkbox.checked,
        }
      }
      return checkbox
    })

    setCheckboxes(updatedCheckboxes)
  }
  return (
    <div>
      <Form>
        {options.map((option) => (
          <Form.Check
            key={option.id}
            type='checkbox'
            id={`checkbox-${option.id}`}
            label={option.label}
            onChange={() => handleCheckboxChange(option.id)}
            style={{
              border: '2px solid #007bff', // Example border color: blue
              borderRadius: '0.25rem', // Adjust border radius if needed
              width: '1.25rem', // Adjust width if needed
              height: '1.25rem', // Adjust height if needed
            }}
          />
        ))}

        {/* checked={checkboxes.find((checkbox) => checkbox.id === option.id)?.checked || false} */}
      </Form>
    </div>
  )
}

export const CheckboxListInColumn = ({
  typeOption,
  columns,
  rows,
  onChange,
  values,
  disabledItems = [],
}) => {
  const [selectedValues, setSelectedValues] = useState([])
  const prevValuesRef = useRef(values)
  const prevSelectedValuesRef = useRef(selectedValues)
  const chunkArray = (arr, size) => {
    const chunkedArr = []
    for (let i = 0; i < arr.length; i += size) {
      chunkedArr.push(arr.slice(i, i + size))
    }
    return chunkedArr
  }

  var options = []

  if (typeOption === 1) {
    options = [
      { id: 'Backing', label: 'Backing' },
      { id: 'Fronting', label: 'Fronting' },
      { id: 'Stopping', label: 'Stopping' },
      { id: 'Initial Consonant Deletion', label: 'Initial Consonant Deletion' },
      { id: 'Final Consonant Deletion', label: 'Final Consonant Deletion' },
      { id: 'Cluster Reductionation', label: 'Cluster Reductionation' },
      { id: 'Gliding', label: 'Gliding' },
      { id: 'Other', label: 'Other' },
    ]
  } else if (typeOption === 2) {
    options = [
      { id: 'Substitutions', label: 'Substitutions' },
      { id: 'Omissions', label: 'Omissions' },
      { id: 'Distortions', label: 'Distortions' },
    ]
  } else if (typeOption === 3) {
    options = [
      { id: 'Apraxia', label: 'Apraxia' },
      { id: 'Dysarthria', label: 'Dysarthria' },
      { id: 'Disturbance of Prosody', label: 'Disturbance of Prosody' },
    ]
  } else if (typeOption === 4) {
    options = [
      { id: 'Initial', label: 'Initial' },
      { id: 'Medial', label: 'Medial' },
      { id: 'Final', label: 'Final' },
    ]
  } else if (typeOption === 5) {
    options = [
      { id: 'General', label: 'General' },
      { id: 'Articulation', label: 'Articulation' },
      { id: 'Phonology', label: 'Phonology' },
    ]
  } else if (typeOption === 6) {
    options = [
      //
      // { id: "ELIT", label: "Emergent Literacy" },
      // { id: "FEED", label: "Feeding" },
      //
      // { id: "READ", label: "Functional Reading" },
      // { id: "AAC", label: "Multi-Modal Functional Communication" },
      // { id: "PRC", label: "Pediatric Reading Comprehension" },
      //
      { id: 'INT', label: 'Speech Intelligibility' },
      { id: 'SLC', label: 'Spoken Language Comprehension' },
      { id: 'SLE', label: 'Spoken Language Expression' },
      { id: 'COG', label: 'Cognition' },
      { id: 'VOIC', label: 'Voice' },
      { id: 'FEED', label: 'Feeding' },
      { id: 'SWAL', label: 'Swallowing' },
      { id: 'FLU', label: 'Fluency' },
      { id: 'PRAG', label: 'Pragmatics' },
      { id: 'AAC', label: 'AAC' },
      // { id: "WRIT", label: "Writting" },
    ]
  } else if (typeOption === 7) {
    options = [
      {
        id: 'Applied Behavioral Analysis',
        label: 'Applied Behavioral Analysis',
      },
      { id: 'Audiologist for', label: 'Audiologist for' },
      {
        id: 'Caregiver Training/Home Exercise Program',
        label: 'Caregiver Training/Home Exercise Program',
      },
      {
        id: 'Clinical Psychology Evaluation',
        label: 'Clinical Psychology Evaluation',
      },
      {
        id: 'Developmental Pediatrician / Neurology',
        label: 'Developmental Pediatrician / Neurology',
      },
      { id: 'Occupational Therapy', label: 'Occupational Therapy' },
      {
        id: 'Physician for Consideration of',
        label: 'Physician for Consideration of',
      },
      { id: 'Physical Therapy', label: 'Physical Therapy' },
      { id: 'Other', label: 'Other' },
    ]
  } else if (typeOption === 8) {
    options = [
      {
        id: 'Physician',
        label: 'Physician',
      },
      { id: 'Audiology', label: 'Audiology' },
      {
        id: 'MBSS/FEES',
        label: 'MBSS/FEES',
      },
      {
        id: 'Psychology',
        label: 'Psychology',
      },
      {
        id: 'Neurology',
        label: 'Neurology',
      },
      { id: 'Optometry', label: 'Optometry' },
      {
        id: 'ENT',
        label: 'ENT',
      },
      { id: 'Developmental/ABA', label: 'Developmental/ABA' },
      { id: 'Occupational Therapy', label: 'Occupational Therapy' },
      { id: 'Gastroenterology ', label: 'Gastroenterology' },
      { id: 'Physical Therapy', label: 'Physical Therapy' },
      { id: 'Other', label: 'Other' },
    ]
  } else if (typeOption === 9) {
    options = [
      { id: 'Ataxia', label: 'Ataxia' },
      { id: 'Hypokinetic', label: 'Hypokinetic' },
      { id: 'Hyperkinetic', label: 'Hyperkinetic' },
      { id: 'Spastic', label: 'Spastic' },
      { id: 'Flaccid', label: 'Flaccid' },
      { id: 'Mixed', label: 'Mixed' },
    ]
  } else if (typeOption === 10) {
    options = ComprehendsOptions
  } else if (typeOption === 11) {
    options = AnswerQuestionOptions
  } else if (typeOption === 12) {
    options = WordUseOptions
  } else if (typeOption === 13) {
    options = [
      { id: '1 word', label: '1 Word' },
      { id: '2 words', label: '2 Words' },
      { id: '3 words', label: '3 Words' },
      { id: '4+ words', label: '4+ Words' },
    ]
  } else if (typeOption === 14) {
    options = NotedLanguageOptions
  } else if (typeOption === 15) {
    options = PragmaticLanguageOptions
  } else if (typeOption === 16) {
    options = LanguageComplexityOptions
  } else if (typeOption === 17) {
    options = [
      { id: 'Occupational Therapy', label: 'Occupational Therapy' },
      { id: 'Physical Therapy', label: 'Physical Therapy' },

      {
        id: 'None',
        label: 'None',
      },
    ]
  } else if (typeOption === 18) {
    options = [
      { id: 'Audiologist for', label: 'Audiologist for' },

      {
        id: 'Clinical Psychology Evaluation',
        label: 'Clinical Psychology Evaluation',
      },
      {
        id: 'Physician for Consideration of',
        label: 'Physician for Consideration of',
      },
      {
        id: 'Developmental Pediatrician/Neurology',
        label: 'Developmental Pediatrician/Neurology',
      },
      //{ id: 'Behavioral Therapy', label: 'Behavioral Therapy' },
      {
        id: 'Applied Behavioral Analysis',
        label: 'Applied Behavioral Analysis',
      },
      { id: 'Other', label: 'Other' },
    ]
  } else if (typeOption === 19) {
    options = OrientationOptions
  } else if (typeOption === 20) {
    options = MemoryOptions
  } else if (typeOption === 21) {
    options = AttentionOptions
  } else if (typeOption === 22) {
    options = ExecutiveFunctioningOptions
  } else if (typeOption === 23) {
    options = PragmaticsOptions
  } else if (typeOption === 24) {
    options = HistoryOptions
  } else if (typeOption === 25) {
    options = QualityResonanceOptions
     
  } else if (typeOption === 26) {
    options = SymptomsOptions
  } else if (typeOption === 27) {
    options =DysfluencyOptions
  } else if (typeOption === 28) {
    options = PhysicalPresentationsOptions
  } else if (typeOption === 29) {
    options =ClutteringOptions
  } else if (typeOption === 30) {
    options =VerbalCommOptions
  } else if (typeOption === 31) {
    options = NonVerbalCommOptions
  } else if (typeOption === 32) {
    options = SocialInteractionOptions
  } else if (typeOption === 33) {
    options =PragVoiceOptions
  }

  useEffect(() => {
    //alert("ppref"+prevValuesRef.current); //&& values !== prevValuesRef.current
    if (values !== undefined) {
      //alert("values : "+values);
      if (typeof values === 'string' && values.trim() !== '') {
        setSelectedValues(values.split(','))
        prevValuesRef.current = values
      }
    }
  }, [values])

  // Call the onChange function when selectedValues change
  useEffect(() => {
    if (
      typeof onChange === 'function' &&
      selectedValues !== prevSelectedValuesRef.current
    ) {
      const selectedValuesString = selectedValues.join(',')
      onChange(selectedValuesString)
      prevSelectedValuesRef.current = selectedValues
    }
  }, []) //onChange,selectedValues D.A was there

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target

    let updatedSelectedValues
    if (checked) {
      updatedSelectedValues = [...selectedValues, value]
    } else {
      updatedSelectedValues = selectedValues.filter((val) => val !== value)
    }
    const updatedSelectedValuesString = updatedSelectedValues?.join(',')
    //alert(updatedSelectedValuesString)
    onChange(updatedSelectedValuesString)
    setSelectedValues(updatedSelectedValues)
  }

  const rowsNeeded = Math.ceil(options.length / columns)
  const chunkedOptions = chunkArray(options, rowsNeeded)
  const isDisabled = (optionId) => {
    return disabledItems.includes(optionId)
  }
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {chunkedOptions.map((column, index) => (
        <div key={index} style={{ flex: 1 }}>
          {column.map((option) => (
            <label
              key={option.id}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <input
                type='checkbox'
                value={option.id}
                className='form-check-input mr-2'
                checked={
                  selectedValues.includes(option.id) || isDisabled(option.id)
                }
                onChange={handleCheckboxChange}
                disabled={isDisabled(option.id)}
                style={{
                  border: '1.5px solid #d3d3d3', // Example border color: blue
                  // borderRadius: '0.25rem', // Adjust border radius if needed
                  // width: '1.0rem', // Adjust width if needed
                  // height: '1.0rem', // Adjust height if needed
                  marginLeft: '2px',
                }}
              />
              <span style={{ marginLeft: '25px' }}>{option.label}</span>
            </label>
          ))}
        </div>
      ))}
    </div>
  )
}

export const Checkbox = ({
  label,
  checked,
  onChange,
  columnName,
  disabled,
}) => {
  const handleCheckboxChange = (event) => {
    const { checked } = event.target
    onChange(columnName, checked) // Pass the columnName and checked value to the parent
  }

  return (
    <label style={{ display: 'flex', alignItems: 'center' }}>
      <input
        type='checkbox'
        checked={checked}
        onChange={handleCheckboxChange}
        disabled={disabled || false}
        className='form-check-input mr-2'
        style={{
          //border: '2px solid #007bff',
          border: '1.5px solid #007bff', // Example border color: blue
          borderRadius: '0.25rem', // Adjust border radius if needed
          width: '1.0rem', // Adjust width if needed
          height: '1.0rem', // Adjust height if needed
          marginLeft: '2px',
        }}
        // style={{ height: "16px", width: "16px" }} // Increase the size of the checkbox here
      />
      <span style={{ marginLeft: '25px', fontWeight: 'bold' }}>{label}</span>
    </label>
  )
}
