import React, { useState, useEffect } from 'react'

export const ServiceDeliveryComp = ({ defaultRow, onRowSelected, visitId }) => {
  const [gridData, setGridData] = useState([
    {
      Discharge_NomsId: 0,
      ResponseType: 1,
      GroupType: 2,
      FCMType: 1,
      VisitId: visitId,
      QuestionCode: null,
      Answer: '0%',
      Question: 'Percent of Total Treatment Time Devoted to this FCM',
      IsLongTermGoal: false,
      RowId: 1,
      CreatedBy: null,
      UpdatedBy: null
    },
    {
      Discharge_NomsId: 0,
      ResponseType: 2,
      GroupType: 2,
      FCMType: 1,
      VisitId: visitId,
      QuestionCode: null,
      Answer: 'Individual',
      Question: 'Predominant Service Delivery Model for this FCM',
      IsLongTermGoal: false,
      RowId: 2,
      CreatedBy: null,
      UpdatedBy: null
    },
    {
      Discharge_NomsId: 0,
      ResponseType: 3,
      GroupType: 2,
      FCMType: 1,
      VisitId: visitId,
      QuestionCode: null,
      Answer: 'Yes',
      Question: 'Were the Treatment Goals Met for this FCM?',
      IsLongTermGoal: false,
      RowId: 3,
      CreatedBy: null,
      UpdatedBy: null
    },
    {
      Discharge_NomsId: 0,
      ResponseType: 4,
      GroupType: 2,
      FCMType: 1,
      VisitId: visitId,
      QuestionCode: null,
      Answer: 'Yes, all',
      Question:
        'Did the Patient Complete the Structured Home Program for this FCM?',
      IsLongTermGoal: false,
      RowId: 4,
      CreatedBy: null,
      UpdatedBy: null
    },
  ])
  const [selectedRow, setSelectedRow] = useState(null)
  const [defaultRowProcessed, setDefaultRowProcessed] = useState(false)

  useEffect(() => {
    if (!defaultRowProcessed && defaultRow && defaultRow.length > 0) {
      const updatedData = gridData.map((row) => {
        const matchingDefaultRow = defaultRow.find(
          (defaultRowItem) =>
            String(defaultRowItem.RowId) === String(row.RowId),
        )

        if (matchingDefaultRow) {
          return {
            ...row,
            Discharge_NomsId: matchingDefaultRow.Discharge_NomsId,
            Answer: matchingDefaultRow.Answer,
            //IsLongTermGoal:matchingDefaultRow.IsLongTermGoal
          }
        }

        return row
      })
      //console.log(JSON.stringify(updatedData));
      setGridData(updatedData)
      setSelectedRow(null) // Clear the selected row, as multiple rows might have changed
      onRowSelected(updatedData) // Notify parent about the updated rows
      setDefaultRowProcessed(true)
    }
  }, [defaultRow, defaultRowProcessed])

  const handleDropdownChange = (id, value) => {
    const selectedValue = value.trim() === '' ? '' : value
    const updatedData = gridData.map((row) => {
      if (row.RowId === id) {
        return { ...row, Answer: selectedValue }
      }
      return row
    })
    setGridData(updatedData)
    setSelectedRow(updatedData.find((row) => row.RowId === id))
    onRowSelected(updatedData)
  }

  //   const handleCheckboxChange = (id, isChecked) => {
  //     //alert(isChecked);
  //     const updatedData = gridData.map((row) => {
  //       if (row.RowId === id) {
  //         return { ...row, IsLongTermGoal: isChecked };
  //       }
  //       return { ...row, IsLongTermGoal: false };
  //     });
  //     setGridData(updatedData);

  //     onRowSelected(updatedData);
  //   };

  const PercetageNos = () => {
    const options = []

    for (let i = 0; i <= 100; i += 5) {
      options.push({ value: i + '%', text: i + '%' })
    }
    return options
    return []
  }

  const getDropdownOptionsForRow = (rowId) => {
    var options = []
    if (rowId === 1) {
      options = PercetageNos()
    } else if (rowId === 2) {
      options = [
        { value: 'Individual', text: 'Individual' },
        { value: 'Group', text: 'Group' },
        { value: 'Training/Consultation', text: 'Training/Consultation' },
        { value: 'Self-contained classroom', text: 'Self-contained classroom' },
        { value: 'Classroom-based', text: 'Classroom-based' },
      ]
    } else if (rowId === 3) {
      options = [
        { value: 'Yes', text: 'Yes' },
        { value: 'No', text: 'No' },
      ]
    } else if (rowId === 4) {
      options = [
        { value: 'Yes, all', text: 'Yes, all' },
        { value: 'Yes, some', text: 'Yes, some' },
        { value: 'Did not complete', text: 'Did not complete' },
        {
          value: 'Home program not established',
          text: 'Home program not established',
        },
      ]
    }
    return options
  }

  return (
    <table className='table table-striped'>
      <thead>
        <tr>
          <th style={{ width: '2%' }}>#</th>
          <th style={{ width: '75%' }}>Service Delivery</th>
          <th style={{ width: '15%' }}>Response values</th>
        </tr>
      </thead>
      <tbody>
        {gridData.map((row) => (
          <tr key={row.RowId}>
            <td>{row.RowId}</td>
            <td>{row.Question}</td>
            <td>
              <select
                className='form-select form-select-sm'
                value={row.Answer}
                onChange={(event) =>
                  handleDropdownChange(row.RowId, event.target.value)
                }
              >
                {getDropdownOptionsForRow(row.ResponseType).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
