import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Variables } from '../../Variables'
import axios from 'axios'
import { VisitProvider, SignLockProvider } from '../../Provider/Visitprovider'
import { toast } from 'react-toastify'
import { NomsComp } from '../Component/NomsComp'
import { ServiceDeliveryComp } from '../Component/ServiceDeliveryComp'
import { NomsService } from '../NomsService'
import { useUser } from '../../Provider/UserContext'
import signLockService from '../../POC/SignLock/Service'
import { NoteType } from '../../Enum'

const apiUrl = Variables.API_URL

export const IntNoms = forwardRef((props, ref) => {
  const VisitId = useContext(VisitProvider)
  const [nomsRow, setNOMSRow] = useState([])
  const [serviceDelRow, setServiceDelRow] = useState([])
  const [IsSignLock, setIsSignLock] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [shouldReloadData, setShouldReloadData] = useState(false)
  const [formData, setFormData] = useState(new NomsService())
  const { userDetails } = useUser()
  const [clientId, setClientId] = useState(null)

  useEffect(() => {
    if (userDetails) {
      //alert(JSON.stringify(userDetails))
      setClientId(userDetails.Clientid)
    }
  }, [userDetails, VisitId])

  useEffect(() => {
    if (VisitId) {
      FetchingSignAndLock()
    }
  }, [VisitId])
  const handleNoms = (selectedRowData) => {
    setNOMSRow(selectedRowData)
  }
  const handleService = (selectedRowData) => {
    setServiceDelRow(selectedRowData)
  }

  useEffect(() => {
    if ((VisitId !== null && clientId != null) || shouldReloadData) {
      //alert(clientId)
      // Check if data should be reloaded
      formData
        .getDischargeNomsData(VisitId, clientId)
        .then((data) => {
          if (data) {
            const nomsData = data.filter(
              (nomsFilterData) =>
                nomsFilterData.GroupType === 1 && nomsFilterData.FCMType === 1,
            )
           //alert(JSON.stringify(nomsData));
            if (nomsData.length > 0) {
              setNOMSRow(nomsData)
            }
            const serviceData = data.filter(
              (nomsFilterData) =>
                nomsFilterData.GroupType === 2 && nomsFilterData.FCMType === 1,
            )
            if (serviceData.length > 0) {
              setServiceDelRow(serviceData)
            }
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
        .finally(() => {
          setShouldReloadData(false)
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VisitId,clientId,shouldReloadData])

  useImperativeHandle(ref, () => ({
    handleButtonClick,
  }))

  const handleButtonClick = (showAlert = true) => {
    // alert(JSON.stringify(nomsRow));
    // alert(JSON.stringify(serviceDelRow));
    const mergedData = [...nomsRow, ...serviceDelRow]
    //return;
    if (mergedData != null && !isSaving) {
      setIsSaving(true)
     
      const updatedMergedData = mergedData.map((item) => ({
        ...item,
        CreatedBy: clientId,
        UpdatedBy: clientId,
      }));
      formData
        .insertDischanrgeNomsData(updatedMergedData)
        .then((response) => {
          setShouldReloadData(true)
          if (showAlert) {
            toast.success(response.MessageDetail)
          }
          //alert(response.data.MessageDetail);
          //setSelectedRow(updatedSelectedRow);
        })
        .catch((error) => {
          console.error('Error saving data "insertDischanrgeNomsData":', error)
        })
        .finally(() => {
          setIsSaving(false)
        })
    }
  }
  const FetchingSignAndLock = () => {
    signLockService
      .getSignLockDataByNoteType(VisitId, NoteType.DischargeNoms)
      .then((response) => {
        if (response) {
          let isSignedAndLocked = response.IsSignedAndLocked
          isSignedAndLocked = isSignedAndLocked || false
          //alert(JSON.stringify(response));
          setIsSignLock(isSignedAndLocked)
        }
        // alert("hiih");
      })
      .catch((error) => {
        console.error('Error FetchingSignAndLock:', error)
      })
  }
  const handleSignLockClick = () => {
    //alert(supervisorValue);
    //return;
    const confirmed = window.confirm('Are you sure you want to sign and lock?')
    if (confirmed) {
      const signLockData = {
        VisitId: VisitId,
        IsSignedAndLocked: true,
        ClientId: clientId,
        VisitTypeId: userDetails.VisitTypeId, // VisitTypeId.SoapNote,
        TherepistId: userDetails.TherapistId,
        VisitStartDateTime: userDetails.Scheduleddatefrom,
        VisitendDateTime: userDetails.Scheduleddateto,
        NoteType: NoteType.DischargeNoms, //This is the type Discharge Noms
        //SignAndLockDate:true,
        SignedBy: userDetails.TherapistId,
      }
      handleButtonClick(false)
      signLockService
        .insertSignLockData(signLockData)
        .then((response) => {
          FetchingSignAndLock()
          alert('Sign and lock confirmed!')
        })
        .catch((error) => {
          console.error('Error inserting data:', error)
        })
    } else {
      alert('Sign and lock cancelled.')
    }
  }
  return (
    <div style={{ display: 'block', width: 'max', padding: 10 }}>
      <table style={{ width: '100%', padding: 5 }}>
        <tr>
          <td style={{ verticalAlign: 'top' }}>
            <p style={{ fontSize: '14px' }}>
              <b>Instructions:</b> Based on your assessment, please answer the
              following questions about the individual's speech intelligibility.
              Intelligibility is based on listener familiarity and length of
              utterance. For all questions, consider chronological age
              expectations when rating the individual.{' '}
            </p>
            <p style={{ fontSize: '14px' }}>
              <b>Note:</b>{' '}
              <i>
                <>
                  <span style={{ textDecoration: 'underline' }}>
                    Please note that the scale is reversed for the first
                    question only.{' '}
                  </span>
                </>
                For example, a score of 0-25% indicates the highest functioning
                for the first question only.{' '}
              </i>
            </p>

            <NomsComp
              visitId={VisitId}
              defaultRow={nomsRow}
              onRowSelected={handleNoms}
            />
          </td>{' '}
        </tr>
        <tr style={{ verticalAlign: 'top' }}>
          {' '}
          <td style={{ verticalAlign: 'top' }}>
            <ServiceDeliveryComp
              visitId={VisitId}
              defaultRow={serviceDelRow}
              onRowSelected={handleService}
            />
          </td>
        </tr>
        <tr>
          <td colSpan={2} style={{ textAlign: 'right' }}>
            <div style={{ marginTop: '10px' }}>
              <button
                style={{ width: '150px', marginRight: '20px' }}
                disabled={IsSignLock || isSaving}
                onClick={handleButtonClick}
                className='btn btn-primary rounded-pill'
              >
                {isSaving ? <b>Saving...</b> : <b>Save</b>}
              </button>
              <button
                onClick={handleSignLockClick}
                disabled={IsSignLock}
                style={{ width: '150px', marginRight: '20px' }}
                className='btn btn-primary rounded-pill'
              >
                <b>Sign and Lock</b>{' '}
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  )
})
