import React, { useState, useEffect, useContext } from 'react'
import { View, Text, Font } from '@react-pdf/renderer'
import { reportStyle } from '../CSS/reportStyle'
import formatDate from '../Utility/Utility'
import axios from 'axios'
import { Variables } from '../Variables'
import HeaderTextContent from './HeaderTextContent'
import { FcmItems } from '../data/options'
import { formatTime } from '../Utility/Utility'
import { PocService } from '../POC/Service/PocService'
import { CPTBillingCodeService } from '../FCM/Component/CPTBillingCodeService'

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})
Font.register({
  family: 'Doulos SIL',
  src: 'https://cdn.jsdelivr.net/npm/doulos-sil@5.000/fonts/DoulosSIL-R.ttf',
})

const stylesTable = {
  page: {
    padding: 10,
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: '10px', // Padding from the left
    paddingRight: '10px',
  },
  cell: {
    width: '25%',
    padding: 1,
    fontSize: '9pt', // Font size 11
  },
  customText: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 9,
  },
  boldText: {
    fontFamily: 'Roboto-Bold',
    fontWeight: 'bold',
    fontSize: 9,
  },
  doulosText: {
    fontFamily: 'Doulos SIL',
    fontWeight: 'bold',
    fontSize: 9,
  },
  containerQ: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    // paddingLeft: "10px", // Padding from the left
    // paddingRight: "10px",
  },
  dataRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '0px solid #FAFAFA',
    padding: 0,
  },
  indexCell: {
    width: '3%',
    fontSize: 9,
  },
  questionCell: {
    width: '100%',
    fontSize: 9,
  },
  answerCell: {
    width: '15%',
    fontSize: 9,
  },
  fcmcolumn: {
    width: '100%', // Adjust as needed
    //padding:0,
    fontSize: 8,
    marginTop: 0, // Adjust as needed
    marginBottom: 0,
  },
  paragraph: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: 9,
  },
  paragraphfcmitem: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: 8,
  },
  footerbox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    padding: '10px',
    paddingLeft: '20px',
   // marginBottom: '20px',
    borderTop: '0px solid #ccc',
    flexDirection: 'row', // Set to 'row' to align boxes horizontally
  },
  signatureBox: {
    flex: 1, // Allow the boxes to take equal width
    border: '0px solid #000', // Add border for visualization
    padding: '0px',
    fontSize: 9,
    textAlign: 'left', // Align text within the box to the left
    lineHeight: '1.2',
  },

  signatureBoxRight: {
    flex: 1, // Allow the boxes to take equal width
    border: '0px solid #000', // Add border for visualization
    padding: '0px',
    fontSize: 9,
    textAlign: 'right', // Align text within the box to the right
    paddingRight: '20px',
    lineHeight: '1.2',
  },
}
const apiCoreUrl = Variables.USER_API_URL
const ContentPOC = ({
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  VisitId,
  FullName,
  clientdata,
  POCData,
  Diagnosis2,
  fcmList,
}) => {
  const [isYes] = useState('Yes')
  const [goalData, setGoalData] = useState([])
  const pocService = new PocService()
  const cptBillingCode = new CPTBillingCodeService()
  const [areaOfAssessment, setAreaOfAssessment] = useState([])
  const [cptCodeList, setcptCodeList] = useState([])
  const LTgoalCounters = {}
  const STgoalCounters = {}

  const getGoalCounter = (GoalCode) => {
    //alert(JSON.stringify(LTgoalCounters));
    if (GoalCode === 'LTG') {
      // LT goals: Use the index + 1
      const value = (LTgoalCounters[GoalCode] || 0) + 1
      LTgoalCounters[GoalCode] = value
      return value
    } else {
      // Other goals: Increment the counter for the specific goal type
      const value = (STgoalCounters[GoalCode] || 0) + 1
      STgoalCounters[GoalCode] = value
      return value
    }
  }

  useEffect(() => {
    if (!VisitId) return
    //alert(""+VisitId)
    axios
      .get(Variables.API_URL + 'SpeechInt/GetAllGoals/' + VisitId)
      .then((response) => {
        const data = response.data.Result
        setGoalData(data)
        //alert(JSON.stringify(data))
      })
      .catch((error) => console.log('Error fetching data:', error))
  }, [VisitId]) //,userDetails, FullName, POCData]);

  useEffect(() => {
    if (VisitId) {
      pocService
        .getAreaOfAssReport(VisitId)
        .then((data) => {
          setAreaOfAssessment(data)
        })
        .catch((error) => {})
    }
  }, [VisitId])

  useEffect(() => {
    if (!VisitId) return
    //alert('POC Data : ' + JSON.stringify(POCData.STIndicated))
    if (POCData) {
      //alert(JSON.stringify(POCData))
      fcmList(POCData.AreaOfAsses?.split(',') || [])
      // pocService
      //   .getAreaOfAss(VisitId)
      //   .then((data) => {
      //     alert(JSON.stringify(POCData.AreaOfAsses?.split(',')))
      //     fcmList(POCData.AreaOfAsses?.split(',') || [])
      //   })
      //   .catch((error) => {})

      cptBillingCode
        .getCPTBillingCode(VisitId)
        .then((data) => {
          // alert(JSON.stringify(data))
          setcptCodeList(data)
        })
        .catch((error) => {})
    }
  }, [VisitId])

  const fetchCPTCodeListData = async (visitId) => {
    //alert('ghgh : ' + JSON.stringify(visitId))
    if (visitId) {
      try {
        //alert(VisitId);
        // const response = await axios.get(
        //   apiCoreUrl +
        //     'SLPBilling/GetBillingChargesData?visitid=916863' +
        //     visitId,
        // )
        // alert(Variables.API_URL + 'POC/GetCPTBillingCode?visitid=' + visitId)
        // const response = await axios.get(
        //   Variables.API_URL + 'POC/GetCPTBillingCode?visitid=' + visitId,
        // )
        // alert('data : ' + JSON.stringify(response))
        // const data = response.data.Result
        //setcptCodeList(data)
        // alert('data : ' + JSON.stringify(data))
      } catch (error) {
        console.log('Error fetching user details:', error)
      }
    }
  }
  const currentDate = new Date()
  const formattedDate = `${
    currentDate.getMonth() + 1
  }/${currentDate.getDate()}/${currentDate.getFullYear()}`

  const removeContentBeforeHyphen = (originalString) => {
    const hyphenIndex = originalString.indexOf('-')
    const resultString =
      hyphenIndex !== -1
        ? originalString.substring(hyphenIndex + 1)
        : originalString
    return resultString
  }
  return (
    <>
      <View
        style={[
          reportStyle.pageSpace,
          {
            flexDirection: 'column',
            marginBottom: '100pt',
          },
        ]}
      >
        {/* <View style={reportStyle.headerTextContainerText}>
          <Text style={reportStyle.headerText}>
            Date of Evaluation:{' '}
            {POCData && formatDate(POCData.CreateDate, false, true)}
            {'   '}
            <Text style={reportStyle.headerTextSpace}> </Text>
            Start: {clientdata && formatTime(clientdata.Scheduleddatefrom)}{' '}
            {'   '}
            <Text style={reportStyle.headerTextSpace}> </Text>
            End:{clientdata && formatTime(clientdata.Scheduleddateto)} {'   '}
          </Text>
        </View> */}
        {/* <HeaderTextContent
          clientdata={clientdata}
          FullName={FullName}
          PocData={POCData}
          Diagnosis2={Diagnosis2}
        ></HeaderTextContent> */}
        <View style={reportStyle.TextHeaderTop}>
          <Text style={reportStyle.TextHeaderText}>
            {FirstName}'s Area(s) of Need
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          {areaOfAssessment.map((item, index) => (
            <Text key={index}>{item}</Text>
          ))}
          {/* {areaOfAssessment && renderColumns(areaOfAssessment)} */}
        </View>
        <View style={reportStyle.TextHeader}>
          {' '}
          <Text style={reportStyle.TextHeaderText}>
            Summary & Recommendations
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {POCData && POCData.SummaryRecommend && POCData.SummaryRecommend}
          </Text>
        </View>
        <View style={reportStyle.TextHeader}>
          {' '}
          <Text style={reportStyle.TextHeaderText}>Plan of Care</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {POCData && POCData.STIndicated === false && (
              <>
                {POCData && POCData.Frequency && (
                  <>
                    {FirstName}'s will attend Speech Therapy {POCData.Frequency}{' '}
                    times per week for {POCData.TimesPerWeek} weeks to treat{' '}
                    {POCData.PrimaryDiagnosis &&
                      POCData.PrimaryDiagnosis.replace(/,/g, ', ')}
                    .
                  </>
                )}
                {POCData && POCData.SLPAOk === isYes && (
                  <> Therapy may be conducted by an SLP-A.{' '}</>
                )}
              </>
            )}
            {POCData && POCData.POCDiscuss && (
              <>
                This plan of care was discussed with the {POCData.POCDiscuss}.
              </>
            )}
          </Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {POCData && POCData.Prognosis && (
              <>
                {FirstName}'s overall prognosis is {POCData.Prognosis}
              </>
            )}
            <Text>
              {POCData && POCData.ReqClientObervation === true ? (
                <>, but requires clinical observation.</>
              ) : (
                '.'
              )}
            </Text>
          </Text>
        </View>
        <View style={reportStyle.TextHeader}>
          {' '}
          <Text style={reportStyle.TextHeaderText}>Therapy Goals</Text>
        </View>
        <View style={reportStyle.paragraph}>
          {goalData &&
            goalData.map((row, index) => (
              <View key={row.GoalId} style={{ flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontSize: '8px',
                      width:
                        row.GoalType !== 'LT' ? '100%' : 'calc(100% - 10px)',
                      paddingLeft: row.GoalType !== 'LT' ? '10px' : '0',
                    }}
                  >
                    {row.GoalType === 'LT' ? (
                      <>
                        <Text
                          style={{
                            fontSize: '8px',
                            paddingTop: '10px',
                            marginTop: '15px',
                          }}
                        >
                          LTG - {getGoalCounter(row.GoalCode)}: Within{' '}
                          {row.Duration} {row.Units}, {FirstName}{' '}
                          {row.GoalDescription.endsWith('?')
                            ? row.GoalDescription.slice(0, -1)
                            : row.GoalDescription}{' '}
                          {row.Accuracy}
                        </Text>
                        .
                      </>
                    ) : row.GoalType === 'Articulation' ? (
                      <>
                        <Text style={{ fontSize: '8px', paddingTop: '10px' }}>
                          STG - {LTgoalCounters.LTG}.
                          {getGoalCounter(row.FCMType)}: Within {row.Duration}{' '}
                          {row.Units}
                        </Text>
                        , given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} will{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.GoalDescription}
                        </Text>{' '}
                        the <Text style={{ fontSize: '8px' }}>{row.Sound}</Text>{' '}
                        sound(s) at the{' '}
                        <Text style={{ fontSize: '8px' }}>{row.AtLevel}</Text>{' '}
                        level with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </>
                    ) : row.GoalType === 'Phonology' ? (
                      <>
                        STG - {LTgoalCounters.LTG}.{getGoalCounter(row.FCMType)}
                        : Within{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.Duration} {row.Units}
                        </Text>
                        , given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} will{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.GoalDescription}
                        </Text>{' '}
                        at the{' '}
                        <Text style={{ fontSize: '8px' }}>{row.AtLevel}</Text>{' '}
                        level with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </>
                    ) : row.GoalType === 'General' ? (
                      <>
                        STG - {LTgoalCounters.LTG}.{getGoalCounter(row.FCMType)}
                        : Within{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.Duration} {row.Units}
                        </Text>
                        , given{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                        {FirstName} will{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.GoalDescription}
                        </Text>{' '}
                        with{' '}
                        <Text style={{ fontSize: '8px' }}>{row.Accuracy}</Text>{' '}
                        accuracy in{' '}
                        <Text style={{ fontSize: '8px' }}>
                          {row.InSessions}
                        </Text>{' '}
                        sessions.
                      </>
                    ) : (
                      <>
                        {' '}
                        <>
                          STG - {LTgoalCounters.LTG}.
                          {getGoalCounter(row.FCMType)}: Within{' '}
                          <Text style={{ fontSize: '8px' }}>
                            {row.Duration} {row.Units}
                          </Text>
                          , given{' '}
                          <Text style={{ fontSize: '8px' }}>{row.Given}</Text>,{' '}
                          {FirstName} will{' '}
                          <Text style={{ fontSize: '8px' }}>
                            {row.GoalDescription}
                          </Text>{' '}
                          with{' '}
                          <Text style={{ fontSize: '8px' }}>
                            {row.Accuracy}
                          </Text>{' '}
                          accuracy in{' '}
                          <Text style={{ fontSize: '8px' }}>
                            {row.InSessions}
                          </Text>{' '}
                          sessions.
                        </>
                      </>
                    )}
                  </Text>
                </View>
              </View>
            ))}
        </View>
        <View style={reportStyle.TextHeader}>
          <Text style={reportStyle.TextHeaderText}>Referred To</Text>
        </View>
        <View style={reportStyle.paragraph}>
          <Text>
            {POCData && POCData.ReferTo && (
              <>
                {FirstName} was referred to Caregiver training ongoing. Home{' '}
                program was established for identified deficits.{' '}
                {POCData.InternalService && (
                  <>
                    {POCData.InternalService.replace(/,/g, ', ')}
                    {POCData.ExternalService && <>, </>}
                  </>
                )}
                {POCData.ExternalService && (
                  <>{POCData.ExternalService.replace(/,/g, ', ')}</>
                )}
                .
                {POCData.ResonforReferal ? (
                  <> {POCData.ResonforReferal.trim()}</>
                ) : (
                  <></>
                )}
              </>
            )}
          </Text>
        </View>
        {cptCodeList && (
          <>
            <View style={reportStyle.TextHeader}>
              <Text style={reportStyle.TextHeaderText}>Billing Codes</Text>
            </View>
            <View
              style={[
                reportStyle.paragraph,
                { flexDirection: 'row', flexWrap: 'wrap', paddingLeft: 10 },
              ]}
            >
              {cptCodeList.map((item, index) => (
                <View key={index} style={{ width: '100%' }}>
                  <Text style={{ fontWeight: 'bold' }}>
                    {index + 1}){' '}
                    <Text style={{ fontWeight: 'bold' }}>{item.CPTCode}</Text> -
                    {removeContentBeforeHyphen(item.CPTCodeDescription)} -{' '}
                    {item.Units}
                  </Text>
                </View>
              ))}
            </View>
          </>
        )}
        <View style={stylesTable.footerbox}>
          <View style={stylesTable.signatureBox}>
            <Text>Electronically Signed By SPEECH THERAPIST:</Text>
            <Text>
              {clientdata && clientdata.TherapistName
                ? clientdata.TherapistName.toUpperCase()
                : ''}
            </Text>
            <Text style={{ fontSize: '8px' }}>
              License Number: {clientdata && clientdata.LicenseNumber}
            </Text>
            <Text style={{ fontSize: '8px' }}>
              Signed Date: {formattedDate}
            </Text>
          </View>

          {/* <View style={stylesTable.signatureBoxRight}>
            <Text>Electronically Signed By REFERRING PHYSICIAN:</Text>
            <Text>
              {' '}
              {clientdata && clientdata.ReferringPhysician
                ? clientdata.ReferringPhysician.toUpperCase()
                : ''}
            </Text>
            <Text style={{ fontSize: '8px' }}>
              NPI Number: {clientdata && clientdata.ReferringPhysicianNPI}
            </Text>
            <Text style={{ fontSize: '8px' }}>
              Signed Date: {formattedDate}
            </Text>
          </View> */}
        </View>
        {/* <View
          style={{ marginBottom: '50px', paddingBottom: '50px' }}
          break
        ></View> */}
      </View>
      {/* <View
        style={{ ...reportStyle.footer, position: 'absolute', bottom: 20 }}
        fixed
      >
        <Text style={reportStyle.footerTextLeft}>All Care Therapies</Text>
        <Text
          style={reportStyle.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        <Text style={reportStyle.footerTextRight} fixed>
          (T) 877-757-8353 (F) 877-753-3009
        </Text>
      </View> */}
    </>
  )
}

export default ContentPOC
