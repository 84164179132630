import React, { useState, useEffect } from 'react'
import {
  Document,
  Page,
  View,
  PDFViewer,
  StyleSheet,
  ReactPDF,
} from '@react-pdf/renderer'
import ReportHeader from './ReportHeader'
import HeaderTextContent from './HeaderTextContent'
import ReportFooter from './ReportFooter'
import ContentPOC from './ContentPOC'
import ContentCHC from './ContentCHC'
import ContentCHA from './ContentCHA'
import ContentSIA from './ContentSIA'
import ContentSI from './ContentSI'
import ContentSLC from './ContentSLC'
import ContentSLE from './ContentSLE'
import ContentCOG from './ContentCOG'
import ContentSWAL from './ContentSWAL'
import ContentFEED from './ContentFEED'
import ContentVOICE from './ContentVOICE'
import ContentFLU from './ContentFLU'
import ContentPRAG from './ContentPRAG'
import ContentAAC from './ContentAAC'

const styles = StyleSheet.create({
  body: {
    paddingTop: 1,
    paddingBottom: 10,
    // paddingHorizontal: 15,
  },
})

const EvalPDFDocs = ({
  pdfFilename,
  POCdata,
  clientdata,
  FullName,
  //diagnosis2,
  FirstName,
  HeOrShe,
  hisHer,
  userDetails,
  dynamicVisitId,
  isAdult,logoUrl,
}) => {
  const [fcmList, setfcmList] = useState(null) //['INT', 'SLC', 'SLE', 'COG']
  const [fcmVisibility, setFcmVisibility] = useState({})
  const [diagnosis2, setDiagnosis2] = useState(null)

  const [INT, setINT] = useState(false)
  const [SLE, setSLE] = useState(false)
  const [SLC, setSLC] = useState(false)
  const [ELIT, setELIT] = useState(false)
  const [SWAL, setSWAL] = useState(false)
  const [WRIT, setWRIT] = useState(false)
  const [VOIC, setVOIC] = useState(false)
  const [PRC, setPRC] = useState(false)
  const [READ, setREAD] = useState(false)
  const [FLU, setFLU] = useState(false)
  const [FEED, setFEED] = useState(false)
  const [COG, setCOG] = useState(false)
  const [PRAG, setPRAG] = useState(false)
  const [AAC, setAAC] = useState(false)

  function getReportHeaderFcmItem() {
    //alert('ghgh : ' + JSON.stringify(fcmList))
    if (fcmList.includes('INT')) {
      setINT(true)
      return
    } else if (fcmList.includes('SLC')) {
      setSLC(true)
      return
    } else if (fcmList.includes('SLE')) {
      setSLE(true)
      return
    } else if (fcmList.includes('COG')) {
      setCOG(true)
      return
    } else if (fcmList.includes('SWAL')) {
      setSWAL(true)
      return
    }
    else if (fcmList.includes('FEED')) {
      setFEED(true)
      return
    }
    else if (fcmList.includes('VOIC')) {
      setVOIC(true)
      return
    }
    else if (fcmList.includes('FLU')) {
      setFLU(true)
      return
    }
    else if (fcmList.includes('PRAG')) {
      setPRAG(true)
      return
    }
    else if (fcmList.includes('AAC')) {
      setAAC(true)
      return
    }
    //alert('sdsf')
  }
  useEffect(() => {
    //console.log('fcmListItem changed:', fcmListItem)
    //alert('isaaee ' + dynamicVisitId)
    //console.log('Setting fcmList:', fcmListItem)
    if (Array.isArray(fcmList)) {
      //alert('isaaee ' + isAdult)
      getReportHeaderFcmItem()
      //const visibilityArray = fcmList.split(',')
      const tabVisibilityData = {
        PRAG: fcmList.includes('PRAG'),
        COG: fcmList.includes('COG'),
        FEED: fcmList.includes('FEED'),
        FLU: fcmList.includes('FLU'),
        READ: fcmList.includes('READ'),
        PRC: fcmList.includes('PRC'),
        VOIC: fcmList.includes('VOIC'),
        WRIT: fcmList.includes('WRIT'),
        SWAL: fcmList.includes('SWAL'),
        SLE: fcmList.includes('SLE'),
        SLC: fcmList.includes('SLC'),
        INT: fcmList.includes('INT'),
        ELIT: fcmList.includes('ELIT'),
        AAC: fcmList.includes('AAC'),
      }
     //alert(JSON.stringify(tabVisibilityData))
      setFcmVisibility(tabVisibilityData)
    }
    //setfcmList(fcmListItem)
  }, [fcmList, setfcmList])
  //   if (fcmVisibility) {
  //     console.log(JSON.stringify(fcmVisibility))
  //     alert(JSON.stringify(fcmVisibility))
  //     //return 'Loading..'
  //   } 
  //alert(JSON.stringify(fcmVisibility))
  const updateDiagnosis2 = (value) => {
    setDiagnosis2(value)
  }
  return (
    <Document title={pdfFilename}>
      <Page size='A4' style={styles.body}>
        <ReportHeader POCData={POCdata} clientdata={clientdata} />
        <HeaderTextContent
          clientdata={clientdata}
          FullName={FullName}
          PocData={POCdata}
          Diagnosis2={diagnosis2}
        ></HeaderTextContent>
        <ContentPOC
          FirstName={FirstName}
          HeOrShe={HeOrShe}
          hisHer={hisHer}
          userDetails={userDetails}
          VisitId={dynamicVisitId}
          clientdata={clientdata}
          FullName={FullName}
          POCData={POCdata}
          Diagnosis2={diagnosis2}
          fcmList={setfcmList}
        ></ContentPOC>
        <ReportFooter></ReportFooter>
      </Page>
      {isAdult !== true ? (
        <Page size='A4' style={styles.body}>
          <ReportHeader POCData={POCdata} clientdata={clientdata} />
          <HeaderTextContent
            clientdata={clientdata}
            FullName={FullName}
            PocData={POCdata}
            Diagnosis2={diagnosis2}
          ></HeaderTextContent>
          <View>
            <ContentCHC
              FirstName={FirstName}
              HeOrShe={HeOrShe}
              hisHer={hisHer}
              userDetails={userDetails}
              VisitId={dynamicVisitId}
              clientdata={clientdata}
              FullName={FullName}
              pocData={POCdata}
              updateDiagnosis2={updateDiagnosis2}
            />
          </View>
          <ReportFooter />
        </Page>
      ) : (
        <Page size='A4' style={styles.body}>
          <ReportHeader POCData={POCdata} clientdata={clientdata} />
          <HeaderTextContent
            clientdata={clientdata}
            FullName={FullName}
            PocData={POCdata}
            Diagnosis2={diagnosis2}
          ></HeaderTextContent>
          <View>
            <ContentCHA
              FirstName={FirstName}
              HeOrShe={HeOrShe}
              hisHer={hisHer}
              userDetails={userDetails}
              VisitId={dynamicVisitId}
              clientdata={clientdata}
              FullName={FullName}
              pocData={POCdata}
              updateDiagnosis2={updateDiagnosis2}
            />
          </View>
          <ReportFooter />
        </Page>
      )}
      {fcmVisibility && fcmVisibility.INT && (
        <>
          {isAdult === true ? (
            <Page size='A4' style={styles.body}>
              <ReportHeader
                POCData={POCdata}
                clientdata={clientdata}
              ></ReportHeader>
              <HeaderTextContent
                clientdata={clientdata}
                FullName={FullName}
                PocData={POCdata}
                Diagnosis2={diagnosis2}
              ></HeaderTextContent>
              <ContentSIA
                HeaderFcm={INT}
                FirstName={FirstName}
                HeOrShe={HeOrShe}
                hisHer={hisHer}
                userDetails={userDetails}
                VisitId={dynamicVisitId}
                clientdata={clientdata}
                FullName={FullName}
                pocData={POCdata}
                Diagnosis2={diagnosis2}
              ></ContentSIA>
              <ReportFooter></ReportFooter>
            </Page>
          ) : (
            <Page size='A4' style={styles.body}>
              <ReportHeader
                POCData={POCdata}
                clientdata={clientdata}
              ></ReportHeader>
              <HeaderTextContent
                clientdata={clientdata}
                FullName={FullName}
                PocData={POCdata}
                Diagnosis2={diagnosis2}
              ></HeaderTextContent>
              <ContentSI
                HeaderFcm={INT}
                FirstName={FirstName}
                HeOrShe={HeOrShe}
                hisHer={hisHer}
                userDetails={userDetails}
                VisitId={dynamicVisitId}
                clientdata={clientdata}
                FullName={FullName}
                pocData={POCdata}
                Diagnosis2={diagnosis2}
              ></ContentSI>
              <ReportFooter></ReportFooter>
            </Page>
          )}
        </>
      )}
      {fcmVisibility && fcmVisibility.SLC && (
        <>
          <Page size='A4' style={styles.body}>
            <ReportHeader
              POCData={POCdata}
              clientdata={clientdata}
            ></ReportHeader>
            <HeaderTextContent
              clientdata={clientdata}
              FullName={FullName}
              PocData={POCdata}
              Diagnosis2={diagnosis2}
            ></HeaderTextContent>
            <ContentSLC
              HeaderFcm={SLC}
              FirstName={FirstName}
              HeOrShe={HeOrShe}
              hisHer={hisHer}
              userDetails={userDetails}
              VisitId={dynamicVisitId}
              clientdata={clientdata}
              FullName={FullName}
              pocData={POCdata}
              Diagnosis2={diagnosis2}
            ></ContentSLC>
            <ReportFooter></ReportFooter>
          </Page>
        </>
      )}
      {fcmVisibility && fcmVisibility.SLE && (
        <>
          <Page size='A4' style={styles.body}>
            <ReportHeader
              POCData={POCdata}
              clientdata={clientdata}
            ></ReportHeader>
            <HeaderTextContent
              clientdata={clientdata}
              FullName={FullName}
              PocData={POCdata}
              Diagnosis2={diagnosis2}
            ></HeaderTextContent>
            <ContentSLE
              HeaderFcm={SLE}
              FirstName={FirstName}
              HeOrShe={HeOrShe}
              hisHer={hisHer}
              userDetails={userDetails}
              VisitId={dynamicVisitId}
              clientdata={clientdata}
              FullName={FullName}
              pocData={POCdata}
              Diagnosis2={diagnosis2}
            ></ContentSLE>
            <ReportFooter></ReportFooter>
          </Page>
        </>
      )}
      {fcmVisibility && fcmVisibility.COG && (
        <>
          <Page size='A4' style={styles.body}>
            <ReportHeader
              POCData={POCdata}
              clientdata={clientdata}
            ></ReportHeader>
            <HeaderTextContent
              clientdata={clientdata}
              FullName={FullName}
              PocData={POCdata}
              Diagnosis2={diagnosis2}
            ></HeaderTextContent>
            <ContentCOG
              FirstName={FirstName}
              HeOrShe={HeOrShe}
              hisHer={hisHer}
              userDetails={userDetails}
              VisitId={dynamicVisitId}
              FullName={FullName}
              HeaderFcm={COG}

              // clientdata={clientdata}

              // pocData={POCdata}
              // Diagnosis2={diagnosis2}
            ></ContentCOG>
            <ReportFooter></ReportFooter>
          </Page>
        </>
      )}
      {fcmVisibility && fcmVisibility.SWAL && (
        <>
          <Page size='A4' style={styles.body}>
            <ReportHeader
              POCData={POCdata}
              clientdata={clientdata}
            ></ReportHeader>
            <HeaderTextContent
              clientdata={clientdata}
              FullName={FullName}
              PocData={POCdata}
              Diagnosis2={diagnosis2}
            ></HeaderTextContent>
            <ContentSWAL
              FirstName={FirstName}
              HeOrShe={HeOrShe}
              hisHer={hisHer}
              userDetails={userDetails}
              VisitId={dynamicVisitId}
              FullName={FullName}
              HeaderFcm={SWAL}
            ></ContentSWAL>
            <ReportFooter></ReportFooter>
          </Page>
        </>
      )}
       {fcmVisibility && fcmVisibility.FEED && (
        <>
          <Page size='A4' style={styles.body}>
            <ReportHeader
              POCData={POCdata}
              clientdata={clientdata}
            ></ReportHeader>
            <HeaderTextContent
              clientdata={clientdata}
              FullName={FullName}
              PocData={POCdata}
              Diagnosis2={diagnosis2}
            ></HeaderTextContent>
            <ContentFEED
              FirstName={FirstName}
              HeOrShe={HeOrShe}
              hisHer={hisHer}
              userDetails={userDetails}
              VisitId={dynamicVisitId}
              FullName={FullName}
              HeaderFcm={FEED}
            ></ContentFEED>
            <ReportFooter></ReportFooter>
          </Page>
        </>
      )}
      {fcmVisibility && fcmVisibility.VOIC && (
        <>
          <Page size='A4' style={styles.body}>
            <ReportHeader
              POCData={POCdata}
              clientdata={clientdata}
            ></ReportHeader>
            <HeaderTextContent
              clientdata={clientdata}
              FullName={FullName}
              PocData={POCdata}
              Diagnosis2={diagnosis2}
            ></HeaderTextContent>
            <ContentVOICE
              FirstName={FirstName}
              HeOrShe={HeOrShe}
              hisHer={hisHer}
              userDetails={userDetails}
              VisitId={dynamicVisitId}
              FullName={FullName}
              HeaderFcm={VOIC}
            ></ContentVOICE>
            <ReportFooter></ReportFooter>
          </Page>
        </>
      )}
      {fcmVisibility && fcmVisibility.FLU && (
        <>
          <Page size='A4' style={styles.body}>
            <ReportHeader
              POCData={POCdata}
              clientdata={clientdata}
            ></ReportHeader>
            <HeaderTextContent
              clientdata={clientdata}
              FullName={FullName}
              PocData={POCdata}
              Diagnosis2={diagnosis2}
            ></HeaderTextContent>
            <ContentFLU
              FirstName={FirstName}
              HeOrShe={HeOrShe}
              hisHer={hisHer}
              userDetails={userDetails}
              VisitId={dynamicVisitId}
              FullName={FullName}
              HeaderFcm={FLU}
            ></ContentFLU>
            <ReportFooter></ReportFooter>
          </Page>
        </>
      )}
      {fcmVisibility && fcmVisibility.PRAG && (
        <>
          <Page size='A4' style={styles.body}>
            <ReportHeader
              POCData={POCdata}
              clientdata={clientdata}
            ></ReportHeader>
            <HeaderTextContent
              clientdata={clientdata}
              FullName={FullName}
              PocData={POCdata}
              Diagnosis2={diagnosis2}
            ></HeaderTextContent>
            <ContentPRAG
              FirstName={FirstName}
              HeOrShe={HeOrShe}
              hisHer={hisHer}
              userDetails={userDetails}
              VisitId={dynamicVisitId}
              FullName={FullName}
              HeaderFcm={PRAG}
            ></ContentPRAG>
            <ReportFooter></ReportFooter>
          </Page>
        </>
      )}
       {fcmVisibility && fcmVisibility.AAC && (
        <>
          <Page size='A4' style={styles.body}>
            <ReportHeader
              POCData={POCdata}
              clientdata={clientdata}
            ></ReportHeader>
            <HeaderTextContent
              clientdata={clientdata}
              FullName={FullName}
              PocData={POCdata}
              Diagnosis2={diagnosis2}
            ></HeaderTextContent>
            <ContentAAC
              FirstName={FirstName}
              HeOrShe={HeOrShe}
              hisHer={hisHer}
              userDetails={userDetails}
              VisitId={dynamicVisitId}
              FullName={FullName}
              HeaderFcm={AAC}
            ></ContentAAC>
            <ReportFooter></ReportFooter>
          </Page>
        </>
      )}
    </Document>
  )
}

export default EvalPDFDocs
